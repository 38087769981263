import {
  GET_RETURN_PACKAGE_DETAIL,
  GET_RETURN_PACKAGE_DETAIL_SUCCESS,
  GET_RETURN_PACKAGE_LOG,
  GET_RETURN_PACKAGE_LOG_SUCCESS,
  GET_RETURN_PACKAGES,
  GET_RETURN_PACKAGES_SUCCESS,
  GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL,
  GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL_SUCCESS,
} from '../../constants/ActionTypes'

export const getReturnPackageLog = (payload) => (
  {
    type: GET_RETURN_PACKAGE_LOG,
    payload
  }
)

export const getReturnPackageLogSuccess = (packagesList) => (
  {
    type: GET_RETURN_PACKAGE_LOG_SUCCESS,
    payload: packagesList
  }
)

export const getReturnPackages = (payload) => (
  {
    type: GET_RETURN_PACKAGES,
    payload
  }
)

export const getReturnPackagesSuccess = (packagesList) => (
  {
    type: GET_RETURN_PACKAGES_SUCCESS,
    payload: packagesList
  }
)

export const getReturnPackageDetail = (payload) => (
  {
    type: GET_RETURN_PACKAGE_DETAIL,
    payload
  }
)

export const getReturnPackageDetailSuccess = (packagesList) => (
  {
    type: GET_RETURN_PACKAGE_DETAIL_SUCCESS,
    payload: packagesList
  }
)

export const getLiveTrackingReturnPackageDetail = (payload) => (
  {
    type: GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL,
    payload
  }
)

export const getLiveTrackingReturnPackageDetailSuccess = (packageLogs) => (
  {
    type: GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL_SUCCESS,
    payload: packageLogs
  }
)
