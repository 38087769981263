import React from 'react'
import {
  Button, Form, Input, message, Spin
} from 'antd'
import { connect } from 'react-redux'
// import GoogleLogin from 'react-google-login'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import {
  hideMessage,
  showAuthLoader,
  userGoogleSignIn,
  userSignIn
} from 'appRedux/actions/Auth'

const FormItem = Form.Item

class SignIn extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader()
        this.props.userSignIn({
          ...values,
          type: 'basic'
        })
      }
    })
  };

  componentWillMount() {
    localStorage.removeItem('api_key')
    localStorage.removeItem('email')
    localStorage.removeItem('account_type')
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        message.error(this.props.alertMessage.toString())
        this.props.hideMessage()
      }, 100)
    }

    if (this.props.authUser !== null) {
      this.props.history.push('/')
    }
  }

  responseGoogleSuccess = (res) => {
    console.log(res)
    if (res.credential) {
      this.props.showAuthLoader()
      this.props.userGoogleSignIn({
        token: res.credential,
        type: 'google'
      })
    }
  }

  responseGoogleFailure = (res) => {
    console.log(res)
    setTimeout(() => {
      message.error(res.error)
      this.props.hideMessage()
    }, 100)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { loader } = this.props

    return (
      <div className="gx-app-login-wrap">
        <Spin spinning={loader} size="large">
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg" />
                <div className="gx-app-logo-wid">
                  <h1>Sign In</h1>
                  <p>Welcome to Bumblebee</p>
                </div>
                <div className="gx-app-logo">
                  <img style={{ width: '15%' }} alt="example" src={require('assets/images/logo1.png')} />
                  <h4
                    style={{
                      display: 'inline-block',
                      color: 'white',
                      marginLeft: '10px' }}
                  >Bumblebee</h4>
                </div>
              </div>
              <div className="gx-app-login-content">
                <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                  <FormItem>
                    {getFieldDecorator('username', {
                      rules: [{
                        required: true, type: 'email', message: 'The input is not valid E-mail!',
                      }],
                    })(
                      <Input placeholder="Email" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: 'Please input your Password!' }],
                    })(
                      <Input type="password" placeholder="Password" />
                    )}
                  </FormItem>
                  <FormItem>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      Sign In
                    </Button>

                  </FormItem>
                  <div className="gx-flex-row gx-justify-content-between">
                    <span style={{marginTop: '10px' }}>or connect with</span>
                    <ul className="gx-social-link">
                      <li>
                        <GoogleOAuthProvider clientId="307473006523-7dquit0pjqnl81ppv4mbm03083np3902.apps.googleusercontent.com">
                          <GoogleLogin
                            buttonText="Login with Google"
                            onSuccess={this.responseGoogleSuccess}
                            onFailure={this.responseGoogleFailure}
                          />
                        </GoogleOAuthProvider>
                      </li>
                    </ul>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn)

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth
  return {loader, alertMessage, showMessage, authUser}
}

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userGoogleSignIn
})(WrappedNormalLoginForm)
