import { ACCOUNT_TYPE } from "../appRedux/sagas/Auth";

const acl = {
  'admin': ['.*'],
  'user': [
    '/packages',
    '/package-detail/\\d+',
    '/signin',
    '/hubcode',
    '/queue-job',
    '/reset-password',
    '/seller',
    '/return-packages',
    '/return-package-detail/\\d+',
    '/dashboard',
    '/export-update-data',
    '/dashboard-error',
    '/dashboard-detail',
    '/ErrorPages/\\d+/index',
    '/mapping-status',
    '/mp-seller-account',
    '/live-tracking-package-detail/\\d+',
    '/live-tracking-return-package-detail/\\d+',
    '/cronjob',
    '/live-tracking-return-packages',
    '/location-mapping',
  ]
}

export const authorize = (role, pathname) => {
  let flag = false
  acl[role].forEach(function (resource) {
    if (RegExp('^' + resource + '$').test(pathname)) {
      flag = true;
    }
  })

  // Not allow google account to direct the Reset Password Form
  if (localStorage.getItem('account_type') === ACCOUNT_TYPE.GOOGLE && pathname === '/reset-password') {
    return false
  }
  return flag
}
