import {
  GET_UNMAPPED_STATUS,
  GET_UNMAPPED_STATUS_SUCCESS,
} from '../../constants/ActionTypes'

export const getUnmappedStatuses = (payload) => (
  {
    type: GET_UNMAPPED_STATUS,
    payload
  }
)

export const getUnmappedStatusesSuccess = (unmappedStatusList) => (
  {
    type: GET_UNMAPPED_STATUS_SUCCESS,
    payload: unmappedStatusList
  }
)
