import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import Settings from './Settings'
import Packages from './Packages'
import Hubcode from './Hubcode'
import QueueJobs from './QueueJobs'
import Auth from './Auth'
import Seller3PL from './Seller3PL'
import Provider from './Provider'
import ReturnPackages from './ReturnPackages'
import Dashboard from './Dashboards/Dashboard'
import MappingStatus from './MappingStatus'
import MpSeller from './MpSellerAccount'
import ApiDocs from "./ApiDocs"
import Log from './Log'
import PackageCancellationLog from './PackageCancellationLog'
import Cronjob from './Cronjob'
import LiveReturnTrackingPackages from './LiveTrackingReturnPackages'
import PackageHistory from './PackageHistory'
import UnmappedStatus from "./UnmappedStatus";
import ErrorData from "./ErrorData";
import UserManagement from "./UserManagement";
import LocationMapping from './LocationMapping'
import ExportUpdateData from './ExportUpdateData'
import DashboardPackages from './Dashboards/Packages'
import DashboardReturnPackages from './Dashboards/ReturnPackages'
import DashboardCancelPackages from './Dashboards/CancelPackages'
import DashboardUserLogin from './Dashboards/UserLogin'
import MenuSideBarReducer from './MenuSidebarSettings'

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  packages: Packages,
  hubcode: Hubcode,
  queueJobs: QueueJobs,
  auth: Auth,
  provider: Provider,
  seller: Seller3PL,
  locationMapping: LocationMapping,
  mpSeller: MpSeller,
  returnPackages: ReturnPackages,
  dashboard: Dashboard,
  dashboardPackages: DashboardPackages,
  dashboardReturnPackages: DashboardReturnPackages,
  dashboardCancelPackages: DashboardCancelPackages,
  dashboardUserLogin: DashboardUserLogin,
  mappingStatus: MappingStatus,
  apiDocs: ApiDocs,
  log: Log,
  packageCancellationLog: PackageCancellationLog,
  cronjob: Cronjob,
  liveTrackingReturnPackages: LiveReturnTrackingPackages,
  packageHistory: PackageHistory,
  unmappedStatus: UnmappedStatus,
  errorData: ErrorData,
  userManagement: UserManagement,
  exportUpdateData: ExportUpdateData,
  menuSideBarSetting: MenuSideBarReducer,
})

export default reducers
