import { GET_MENU_LIST, UPDATE_MENU_LIST } from "../../constants/ActionTypes"
import { all, call, fork, put, take, takeEvery } from "redux-saga/effects"
import { getMenuSettingsListSuccess, updateMenuSidebarListSuccess } from "../actions/MenuSidebarSettings"
import { callApi } from "../../util/api"

const getMenuSidebarSettingsList = (payload) => callApi(
  '/api/v1.0/menu-settings/getMenuSettingsList',
  'GET',
  payload
)

function* getMenuSettingList({ payload }) {
  const menuListResponse = yield call(getMenuSidebarSettingsList, payload)
  if (menuListResponse.success) {
    yield put(getMenuSettingsListSuccess(menuListResponse))
  }
}

const updateMenuSidebarSettingsList = (payload) => callApi(
  '/api/v1.0/menu-settings/update',
  'POST',
  payload
)

function* updateSidebarSettingsList({ payload }) {
  const res = yield call(updateMenuSidebarSettingsList, payload)
  if (res?.success) {
    yield put(updateMenuSidebarListSuccess(res))
  }
}

export function* getMenuSidebarListWatcher() {
  yield takeEvery(GET_MENU_LIST, getMenuSettingList)
}

export function* updateMenuSidebarListWatcher() {
  yield takeEvery(UPDATE_MENU_LIST, updateSidebarSettingsList)
}

export default function* rootSaga() {
  yield all([
    fork(updateMenuSidebarListWatcher),
    fork(getMenuSidebarListWatcher)
  ])
}

