import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_SYNC_TIME,
  GET_DASHBOARD_SYNC_TIME_SUCCESS,
  EXPORT_DASHBOARD,
  EXPORT_DASHBOARD_SUCCESS
} from '../../../constants/ActionTypes'

export const getDashboard = (payload) => (
  {
    type: GET_DASHBOARD,
    payload
  }
)

export const getDashboardSuccess = (packagesList) => (
  {
    type: GET_DASHBOARD_SUCCESS,
    payload: packagesList
  }
)

export const getDashboardSyncTime = (payload) => (
  {
    type: GET_DASHBOARD_SYNC_TIME,
    payload
  }
)

export const getDashboardSyncTimeSuccess = (syncTimeList) => (
  {
    type: GET_DASHBOARD_SYNC_TIME_SUCCESS,
    payload: syncTimeList
  }
)

// Export Dashboard
export const exportDashboard = (payload) => (
  {
    type: EXPORT_DASHBOARD,
    payload
  }
)

export const exportDashboardSuccess = (payload) => (
  {
    type: EXPORT_DASHBOARD_SUCCESS,
    payload: payload
  }
)
