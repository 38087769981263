import React, {Component} from 'react'
import {connect} from 'react-redux'
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch} from 'react-router-dom'

import SignIn from '../SignIn'
import MainApp from './MainApp'
import {setInitUrl} from 'appRedux/actions/Auth'
import {onNavStyleChange} from 'appRedux/actions/Setting'
import { authorize } from '../../util/acl'
import { isValid, getRole} from '../../util/jwtToken'

const RestrictedRoute = ({component: Component, apiKey, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      apiKey && isValid(apiKey)
        ? (
            authorize(getRole(apiKey), props.location.pathname)
            ? <Component {...props} />
            : <Redirect to = {'/ErrorPages/403/index'} />
          )
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />
    }
  />

class App extends Component {

  setLayoutType = () => {
    document.body.classList.remove('boxed-layout')
    document.body.classList.remove('framed-layout')
    document.body.classList.add('full-layout')
  };

  setNavStyle = () => {
    document.body.classList.add('full-scroll')
    document.body.classList.add('horizontal-layout')
  };

  componentWillMount() {
    // if (this.props.initURL === '') {
    //   this.props.setInitUrl(this.props.history.location.pathname)
    // }
    const params = new URLSearchParams(this.props.location.search)

    if (params.has('nav-style')) {
      this.props.onNavStyleChange(params.get('nav-style'))
    }
  }

  render() {
    const {match, location, layoutType, navStyle, initURL} = this.props
    // eslint-disable-next-line no-undef
    const apiKey = localStorage.getItem('api_key')

    if (location.pathname === '/') {
      if (apiKey === null) {
        return ( <Redirect to={'/signin'}/> )
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/dashboard'}/> )
      } else {
        return ( <Redirect to={initURL}/> )
      }
    }

    this.setLayoutType(layoutType)

    this.setNavStyle(navStyle)
    return (
      <Switch>
        <Route exact path='/signin' component={SignIn}/>
        <RestrictedRoute path={`${match.url}`} component={MainApp} apiKey={apiKey} location={location}/>
      </Switch>
    )
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {navStyle, layoutType} = settings
  const {authUser, initURL} = auth
  return {navStyle, layoutType, authUser, initURL}
}

export default connect(mapStateToProps, { setInitUrl, onNavStyleChange })(App)
