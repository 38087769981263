import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {
  GET_ERROR_DATA,
} from '../../constants/ActionTypes'
import {
  getErrorDataSuccess,
} from '../actions/ErrorData'

import { callApi } from '../../util/api'

const fetchErrorDataRequest = (payload) => callApi(
  '/api/v1.0/error-data',
  'GET',
  payload
)

function* fetchErrorData({ payload }) {
  const fetchedErrorData = yield call(fetchErrorDataRequest, payload)
  yield put(getErrorDataSuccess(fetchedErrorData))
}

export function* fetchErrorDataWatcher() {
  yield takeEvery(GET_ERROR_DATA, fetchErrorData)
}

export default function* rootSaga() {
  yield all([
    fork(fetchErrorDataWatcher)
  ])
}
