import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {
  GET_DASHBOARD_TOP_USERS_LOGIN,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN,
} from '../../../constants/ActionTypes'
import {
  getDashboardTopUserLoginSuccess,
  getDashboardHeaderTotalNumberUserLoginSuccess,
} from '../../actions/Dashboards/UserLogin'

import { callApi } from '../../../util/api'

// User Login
const fetchDashboardTopUserLoginRequest = (payload) => callApi(
  "/api/v1.0/dashboard/topUsersWithNumberOfLogins",
  "GET",
  payload
)

const fetchDashboardTotalNumberUserLoginRequest = (payload) => callApi(
  "/api/v1.0/dashboard/totalNumberOfLogins",
  "GET",
  payload
)

function* fetchDashboardTopUserLogin({ payload }) {
  const fetchedTopUserLogin = yield call(fetchDashboardTopUserLoginRequest, payload);
  const fetchedTotalNumberUserLogin = yield call(fetchDashboardTotalNumberUserLoginRequest, payload);
  yield put(getDashboardTopUserLoginSuccess(fetchedTopUserLogin, fetchedTotalNumberUserLogin));
}

export function* fetchDashboardTopUserLoginWatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_USERS_LOGIN, fetchDashboardTopUserLogin);
}

function* fetchDashboardHeaderTotalNumberUserLogin({ payload }) {
  const fetchedHeaderTotalNumberUserLogin = yield call(fetchDashboardTotalNumberUserLoginRequest, payload);
  yield put(getDashboardHeaderTotalNumberUserLoginSuccess(fetchedHeaderTotalNumberUserLogin));
}

export function* fetchDashboardHeaderTotalNumberUserLoginWatcher() {
  yield takeEvery(GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN, fetchDashboardHeaderTotalNumberUserLogin);
}

export default function* rootSaga() {
  yield all([
    fork(fetchDashboardTopUserLoginWatcher),
    fork(fetchDashboardHeaderTotalNumberUserLoginWatcher)
  ])
}
