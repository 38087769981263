import {
  GET_MP_SELLER,
  GET_MP_SELLER_SUCCESS,
  EDIT_MP_SELLER,
  EDIT_MP_SELLER_SUCCESS,
  EDIT_MP_SELLER_FAILED,
  ADD_MP_SELLER,
  ADD_MP_SELLER_SUCCESS,
  ADD_MP_SELLER_FAILED,
  DELETE_MP_SELLER,
  DELETE_MP_SELLER_SUCCESS,
  DELETE_MP_SELLER_FAILED,
} from '../../constants/ActionTypes'

export const getMpSeller = (payload) => (
  {
    type: GET_MP_SELLER,
    payload
  }
)

export const getMpSellerSuccess = (MpSellerList) => (
  {
    type: GET_MP_SELLER_SUCCESS,
    payload: MpSellerList
  }
)

export const editMpSeller = (payload) => (
  {
    type: EDIT_MP_SELLER,
    payload
  }
)

export const editMpSellerSuccess = (message) => (
  {
    type: EDIT_MP_SELLER_SUCCESS,
    payload: message
  }
)

export const editMpSellerFailed = (message) => {
  return {
    type: EDIT_MP_SELLER_FAILED,
    payload: message
  }
}

export const addMpSeller = (payload) => (
  {
    type: ADD_MP_SELLER,
    payload
  }
)

export const addMpSellerSuccess = payload => (
  {
    type: ADD_MP_SELLER_SUCCESS,
    payload
  }
)

export const addMpSellerFailed = payload => {
  return {
    type: ADD_MP_SELLER_FAILED,
    payload
  }
}

export const deleteMpSeller = (payload) => (
  {
    type: DELETE_MP_SELLER,
    payload
  }
)

export const deleteMpSellerSuccess = payload => (
  {
    type: DELETE_MP_SELLER_SUCCESS,
    payload
  }
)

export const deleteMpSellerFailed = payload => (
  {
    type: DELETE_MP_SELLER_FAILED,
    payload
  }
)
