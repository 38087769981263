import {
  GET_UNMAPPED_STATUS,
  GET_UNMAPPED_STATUS_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  unmappedStatusList: {},
  loading: false
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

  case GET_UNMAPPED_STATUS:
    return {
      ...state,
      loading: true
    }

  case GET_UNMAPPED_STATUS_SUCCESS:
    // if (action.payload.total === 0) {
    //   localStorage.removeItem('keySearch')
    //   localStorage.removeItem('valueSearch')
    // }
    return {
      ...state,
      loading: false,
      unmappedStatusList: action.payload
    }
    default:
    return state
  }
}
