export const getRole = (jwtToken) => {
    const [, bodyb64, ] = jwtToken.split('.')
    const payload = getPayload(bodyb64)
    return (payload || {}).role
}

export const isValid = (jwtToken) => {
    const [, bodyb64, ] = jwtToken.split('.')
    const payload = getPayload(bodyb64)
    return (payload || { exp:0 }).exp >= new Date().getTime()/1000
}

export const isAdmin = (jwtToken) => {
  return getRole(jwtToken) === 'admin'
}

const getPayload = (bodyb64) => {
    try {
      const decodedString = base64UrlDecode(bodyb64);
      return JSON.parse(decodedString)
    } catch (e) {
      return []
    }
}

const base64UrlDecode = (encodedString) => {
  return atob(encodedString.replace('.','+').replace('_','/').replace('-','='))
}
