import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import { GET_USERS_LIST, UPDATE_USER } from '../../constants/ActionTypes'

import { callApi } from '../../util/api'
import {
  getUsersListSuccess,
  updateUserSuccess, updateUserFail
} from "../actions";

const fetchUsersListRequest = (payload) => callApi(
  '/api/v1.0/user/getActiveUsersList',
  'GET',
  payload
)

const updateUserRequest = (payload) => callApi(
  '/api/v1.0/user/update',
  'POST',
  payload
)

function* fetchUserList({ payload }) {
  const fetchedUsersList = yield call(fetchUsersListRequest, payload)
  yield put(getUsersListSuccess(fetchedUsersList))
}

function* updateUser({ payload }) {
  const updateUserResponse = yield call(updateUserRequest, payload)
  if (updateUserResponse.success) {
    yield put(updateUserSuccess(updateUserResponse))
  } else {
    yield put(updateUserFail(updateUserResponse))
  }
}

export function* fetchUsersListWatcher() {
  yield takeEvery(GET_USERS_LIST, fetchUserList)
}

export function* updateUserWatcher() {
  yield takeEvery(UPDATE_USER, updateUser)
}

export default function* rootSaga() {
  yield all([
    fork(fetchUsersListWatcher),
    fork(updateUserWatcher),
  ])
}
