import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGE_DETAIL,
  GET_PACKAGE_DETAIL_SUCCESS,
  GET_LIVE_TRACKING_PACKAGE_DETAIL,
  GET_LIVE_TRACKING_PACKAGE_DETAIL_SUCCESS,
  GET_PACKAGES_LOG,
  GET_PACKAGES_LOG_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  packagesList: {},
  packageLogs: undefined,
  loading: false
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

  case GET_PACKAGES:
    return {
      ...state,
      loading: true
    }

  case GET_PACKAGES_SUCCESS:
    if (action.payload.total === 0) {
      localStorage.removeItem('keySearch')
      localStorage.removeItem('valueSearch')
    }
    return {
      ...state,
      loading: false,
      packagesList: action.payload
    }

  case GET_PACKAGE_DETAIL:
    return {
      ...state,
      loading: true
    }

  case GET_PACKAGE_DETAIL_SUCCESS:
    return {
      ...state,
      loading: false,
      packageLogs: action.payload
    }

    case GET_PACKAGES_LOG:
      return {
        ...state,
        loading:true
      }

    case GET_PACKAGES_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        packagesList: action.payload
      }

    case GET_LIVE_TRACKING_PACKAGE_DETAIL:
      return {
        ...state,
        loading: true
      }

    case GET_LIVE_TRACKING_PACKAGE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        packageLogs: action.payload
      }


    default:
    return state
  }
}
