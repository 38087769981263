import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {
  GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES,
  GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES
} from '../../../constants/ActionTypes'
import {
  getDashboardTop3PLCancelPackagesSuccess,
  getDashboardHeaderTotalNumberCancelPackagesSuccess,
  getDashboardTop3PLErrorCancelPackagesSuccess,
  getDashboardHeaderTotalNumberErrorCancelPackagesSuccess
} from '../../actions/Dashboards/CancelPackages'

import { callApi } from '../../../util/api'

// Cancel Packages
const fetchDashboardTop3PLCancelPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/top3PLWithNumberOfCancelPackages",
  "GET",
  payload
)

const fetchDashboardTotalNumberCancelPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/totalNumberOfCancelPackages",
  "GET",
  payload
)

function* fetchDashboardTop3PLCancelPackages({ payload }) {
  const fetched3PLCancelPackages = yield call(fetchDashboardTop3PLCancelPackagesRequest, payload);
  const fetchedTotalNumberCancelPackages = yield call(fetchDashboardTotalNumberCancelPackagesRequest, payload);
  yield put(getDashboardTop3PLCancelPackagesSuccess(fetched3PLCancelPackages, fetchedTotalNumberCancelPackages));
}

export function* fetchDashboardTop3PLCancelPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES, fetchDashboardTop3PLCancelPackages);
}

function* fetchDashboardHeaderTotalNumberCancelPackages({ payload }) {
  const fetchedHeaderTotalNumberCancelPackages = yield call(fetchDashboardTotalNumberCancelPackagesRequest, payload);
  yield put(getDashboardHeaderTotalNumberCancelPackagesSuccess(fetchedHeaderTotalNumberCancelPackages));
}

export function* fetchDashboardHeaderTotalNumberCancelPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES, fetchDashboardHeaderTotalNumberCancelPackages);
}

// Error Cancel Packages
const fetchDashboardTop3PLErrorCancelPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/top3PLWithNumberOfErrorCancelPackages",
  "GET",
  payload
)

const fetchDashboardTotalNumberErrorCancelPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/totalNumberOfErrorCancelPackages",
  "GET",
  payload
)

function* fetchDashboardTop3PLErrorCancelPackages({ payload }) {
  const fetched3PLErrorCancelPackages = yield call(fetchDashboardTop3PLErrorCancelPackagesRequest, payload);
  const fetchedTotalNumberErrorCancelPackages = yield call(fetchDashboardTotalNumberErrorCancelPackagesRequest, payload);
  yield put(getDashboardTop3PLErrorCancelPackagesSuccess(fetched3PLErrorCancelPackages, fetchedTotalNumberErrorCancelPackages));
}

export function* fetchDashboardTop3PLErrorCancelPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES, fetchDashboardTop3PLErrorCancelPackages);
}

function* fetchDashboardHeaderTotalNumberErrorCancelPackages({ payload }) {
  const fetchedHeaderTotalNumberErrorCancelPackages = yield call(fetchDashboardTotalNumberErrorCancelPackagesRequest, payload);
  yield put(getDashboardHeaderTotalNumberErrorCancelPackagesSuccess(fetchedHeaderTotalNumberErrorCancelPackages));
}

export function* fetchDashboardHeaderTotalNumberErrorCancelPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES, fetchDashboardHeaderTotalNumberErrorCancelPackages);
}

export default function* rootSaga() {
  yield all([
    fork(fetchDashboardTop3PLCancelPackagesWatcher),
    fork(fetchDashboardHeaderTotalNumberCancelPackagesWatcher),
    fork(fetchDashboardTop3PLErrorCancelPackagesWatcher),
    fork(fetchDashboardHeaderTotalNumberErrorCancelPackagesWatcher),
  ])
}
