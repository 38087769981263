import {
  GET_PROVIDERS,
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_BY_COUNTRY,
  GET_PROVIDERS_BY_COUNTRY_SUCCESS,
  GET_PROVIDER_SELLERS_BY_COUNTRY,
  GET_PROVIDER_SELLERS_BY_COUNTRY_SUCCESS,
  UPDATE_PROVIDER_BY_ID,
  UPDATE_PROVIDER_BY_ID_SUCCESS,
  UPDATE_PROVIDER_BY_ID_FAIL,
  GET_PROVIDERS_PAGINATION,
  GET_PROVIDERS_PAGINATION_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  providersList: {},
  loading: false,
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROVIDER_BY_ID:
    case GET_PROVIDERS_PAGINATION:
    case GET_PROVIDERS:
    case GET_PROVIDERS_BY_COUNTRY:
    case GET_PROVIDER_SELLERS_BY_COUNTRY:
      return {
        ...state,
        loading: true
      }

    case GET_PROVIDERS_SUCCESS:
    case GET_PROVIDERS_BY_COUNTRY_SUCCESS:
    case GET_PROVIDERS_PAGINATION_SUCCESS:
    case GET_PROVIDER_SELLERS_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        providersList: action.payload
      }

    /**
     * After update, reload to get pagination
     * No need to update store state after update provider
     */
    case UPDATE_PROVIDER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_PROVIDER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      }

    default:
      return state
  }
}
