import {
  GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES,
  GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS
} from '../../../constants/ActionTypes'

// Return Packages
export const getDashboardTop3PLReturnPackages = (payload) => (
  {
    type: GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES,
    payload
  }
)

export const getDashboardTop3PLReturnPackagesSuccess = (top3PLReturnPackages, totalNumberReturnPackages) => (
  {
    type: GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES_SUCCESS,
    payload: { top3PLReturnPackages, totalNumberReturnPackages }
  }
)

export const getDashboardHeaderTotalNumberReturnPackages = (payload) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES,
    payload
  }
)

export const getDashboardHeaderTotalNumberReturnPackagesSuccess = (headerTotalNumberReturnPackages) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES_SUCCESS,
    payload: headerTotalNumberReturnPackages
  }
)

// Error Return Packages
export const getDashboardTop3PLErrorReturnPackages = (payload) => (
  {
    type: GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES,
    payload
  }
)

export const getDashboardTop3PLErrorReturnPackagesSuccess = (top3PLErrorReturnPackages, totalNumberErrorReturnPackages) => (
  {
    type: GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES_SUCCESS,
    payload: { top3PLErrorReturnPackages, totalNumberErrorReturnPackages }
  }
)

export const getDashboardHeaderTotalNumberErrorReturnPackages = (payload) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES,
    payload
  }
)

export const getDashboardHeaderTotalNumberErrorReturnPackagesSuccess = (headerTotalNumberErrorReturnPackages) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES_SUCCESS,
    payload: headerTotalNumberErrorReturnPackages
  }
)

// Live Tracking Error Return Packages
export const getDashboardTop3PLLiveTrackingErrorReturnPackages = (payload) => (
  {
    type: GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES,
    payload
  }
)

export const getDashboardTop3PLLiveTrackingErrorReturnPackagesSuccess = (top3PLLiveTrackingErrorReturnPackages, totalNumberLiveTrackingErrorReturnPackages) => (
  {
    type: GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS,
    payload: { top3PLLiveTrackingErrorReturnPackages, totalNumberLiveTrackingErrorReturnPackages }
  }
)

export const getDashboardHeaderTotalNumberLiveTrackingErrorReturnPackages = (payload) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES,
    payload
  }
)

export const getDashboardHeaderTotalNumberLiveTrackingErrorReturnPackagesSuccess = (headerTotalNumberLiveTrackingErrorReturnPackages) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS,
    payload: headerTotalNumberLiveTrackingErrorReturnPackages
  }
)
