import {
  EDIT_MAPPING_STATUS,
  EDIT_MAPPING_STATUS_SUCCESS,
  GET_MAPPING_STATUS,
  GET_MAPPING_STATUS_SUCCESS,
  EDIT_MAPPING_STATUS_FAILED,
  EDIT_CUSTOM_DATA_FIELDS,
  EDIT_CUSTOM_DATA_FIELDS_SUCCESS,
  EDIT_CUSTOM_DATA_FIELDS_FAILED,
  GET_BUMBLEBEE_STATUS_LIST,
  GET_BUMBLEBEE_STATUS_LIST_SUCCESS,
  ADD_MAPPING_STATUS,
  ADD_MAPPING_STATUS_SUCCESS,
  ADD_MAPPING_STATUS_FAILED,
  DELETE_MAPPING_STATUS,
  DELETE_MAPPING_STATUS_SUCCESS,
  DELETE_MAPPING_STATUS_FAILED,
  IMPORT_MAPPING_STATUS,
  IMPORT_MAPPING_STATUS_SUCCESS,
  IMPORT_MAPPING_STATUS_FAILED,
  RESET,
  DOWNLOAD_EXAMPLE,
  DOWNLOAD_EXAMPLE_SUCCESS,
  EXPORT_MAPPING_STATUS,
  EXPORT_MAPPING_STATUS_SUCCESS,
  GET_BUMBLEBEE_FORWARD_STATUS,
  GET_BUMBLEBEE_RETURN_STATUS,
  GET_BUMBLEBEE_FORWARD_STATUS_SUCCESS,
  GET_BUMBLEBEE_RETURN_STATUS_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  mappingStatusList: {},
  bumblebeeStatusList: {},
  bumblebeeForwardStatus: {},
  bumblebeeReturnStatus: {},
  isToReload: false,
  isSuccess: undefined,
  downloadFile: undefined,
  exportFile: undefined

}

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_MAPPING_STATUS:
      return {
        ...state,
        loading: true
      }

    case GET_MAPPING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isToReload: false,
        mappingStatusList: action.payload
      }

    case GET_BUMBLEBEE_STATUS_LIST:
    case GET_BUMBLEBEE_FORWARD_STATUS:
    case GET_BUMBLEBEE_RETURN_STATUS:
      return {
        ...state,
        loading: true
      }

    case GET_BUMBLEBEE_STATUS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bumblebeeStatusList: action.payload
      }
    case EDIT_CUSTOM_DATA_FIELDS:
    case EDIT_MAPPING_STATUS:
    case ADD_MAPPING_STATUS:
    case DELETE_MAPPING_STATUS:
    case IMPORT_MAPPING_STATUS:
      return {
        ...state,
        loading: true,
      }
    
    case EDIT_CUSTOM_DATA_FIELDS_SUCCESS:
    case EDIT_MAPPING_STATUS_SUCCESS:
    case ADD_MAPPING_STATUS_SUCCESS:
    case DELETE_MAPPING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isToReload: true,
        message: action.payload.message,
        isSuccess: action.payload.isSuccess
      }

    case EDIT_CUSTOM_DATA_FIELDS_FAILED:
    case EDIT_MAPPING_STATUS_FAILED:
    case ADD_MAPPING_STATUS_FAILED:
    case DELETE_MAPPING_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.isSuccess
      }

    case IMPORT_MAPPING_STATUS_SUCCESS:
    case IMPORT_MAPPING_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.isSuccess,
      }

    case RESET:
      return {
        ...state,
        isSuccess: undefined
      }
    case DOWNLOAD_EXAMPLE:
    case EXPORT_MAPPING_STATUS:
      return {
        ...state,
        loading: true
      }

    case DOWNLOAD_EXAMPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadFile: action.payload,
      }

    case EXPORT_MAPPING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        exportFile: action.payload,
      }
    
    case GET_BUMBLEBEE_FORWARD_STATUS_SUCCESS:
      return{
        ...state,
        bumblebeeForwardStatus: action.payload
      }
      
    case GET_BUMBLEBEE_RETURN_STATUS_SUCCESS:
      return{
       ...state,
        bumblebeeReturnStatus: action.payload
      }
    default:
      return state
  }
}
