import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'
import { getMenuSettingsList, updateMenuSidebarList } from '../../appRedux/actions/MenuSidebarSettings'
import CustomScrollbars from 'util/CustomScrollbars'
import SidebarLogo from './SidebarLogo'

import Auxiliary from 'util/Auxiliary'
import UserProfile from './UserProfile'
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from '../../constants/ThemeSetting'

import { isAdmin } from '../../util/jwtToken'
import {
  ADMIN_ONLY_MENU_GROUP, ALLOW_ALL_USERS_MENU_GROUP,
  MenuState
} from '../../appRedux/reducers/MenuSidebarSettings'

const SubMenu = Menu.SubMenu

class SidebarContent extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.getMenuSettingsList()
  }

  componentDidUpdate(prevProps) {
    // this.props.getMenuSettingsList()
  }

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return 'gx-no-header-notifications'
    }
    return ''
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return 'gx-no-header-submenu-popup'
    }
    return ''
  };

  groupSubMenuByNameArray = (itemsList, menuGroupName) => {
    return itemsList.reduce((prev, item) => {
      const menuGroup = item.subMenu
      if (menuGroup && menuGroup === menuGroupName) {
        prev.push(item)
      }
      return prev
    }, [])
  }

  subMenuItemListGenerate(subMenuItemsList, navStyle) {
    var arr = []
    for (const [subMenuKey, value] of Object.entries(subMenuItemsList)) {
      const itemList = value?.map(submenuItem => {
        const { key: menuItemKey, status, icon, routeLink, title } = submenuItem
        return (<Menu.Item key={menuItemKey} hidden={status === MenuState.INACTIVE}>
          <Link to={routeLink}><i className={icon} />
            <span>{title}</span>
          </Link>
        </Menu.Item>)
      })
      var iconClass = {
        'Mapping Status': 'icon icon-edit',
        'Data Error and Sync': 'icon icon-chart',
        'API Document': 'icon icon-files'
      }
      if (ALLOW_ALL_USERS_MENU_GROUP.includes(subMenuKey)) {
        const menuGroup = <SubMenu
          key={subMenuKey} className={this.getNavStyleSubMenuClass(navStyle)}
          title={<span> <i className={iconClass[subMenuKey] ? iconClass[subMenuKey] : 'icon icon-files'} /><span>{subMenuKey}</span></span>}>
          {itemList}
        </SubMenu>
        arr.push(menuGroup)
      }
      if (ADMIN_ONLY_MENU_GROUP.includes(subMenuKey)) {
        const menuGroup = <SubMenu
          hidden={!isAdmin(localStorage.api_key)}
          key={subMenuKey} className={this.getNavStyleSubMenuClass(navStyle)}
          title={<span> <i className={iconClass[subMenuKey] ? iconClass[subMenuKey] : 'icon icon-files'} /><span>{subMenuKey}</span></span>}>
          {itemList}
        </SubMenu>

        arr.push(menuGroup)
      }
    }
    return arr
  }

  generateOrderedMenuItemList = (menuItemList, navStyle) => {
    var result = []
    var existedMenuGroup = []

    for (var menuItem of menuItemList) {
      if (menuItem.subMenu) {
        const { subMenu: subMenuName } = menuItem
        if (!existedMenuGroup.includes(subMenuName) && menuItem.status === MenuState.ACTIVE) {
          var subMenuList = {}
          subMenuList[subMenuName] = this.groupSubMenuByNameArray(menuItemList, subMenuName)
          const menuGroupItemArr = this.subMenuItemListGenerate(subMenuList, navStyle)
          result.push(menuGroupItemArr)
          existedMenuGroup.push(subMenuName)
        }
      } else {
        const { key: menuItemKey, status, icon, routeLink, title } = menuItem
        const item = (<Menu.Item key={menuItemKey} hidden={status === MenuState.INACTIVE}>
          <Link to={routeLink}><i className={icon} />
            <span>{title}</span>
          </Link>
        </Menu.Item>)
        result.push(item)
      }
    }

    return result
  }

  render() {
    const { navStyle, pathname, menuItems, loading } = this.props
    const selectedKeys = pathname.substr(1)
    const defaultOpenKeys = selectedKeys.split('/')[1]
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={'dark'}
              mode="inline">
              {menuItems && this.generateOrderedMenuItemList(menuItems, navStyle)}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    )
  }
}

SidebarContent.propTypes = {}
const mapStateToProps = ({ settings, menuSideBarSetting }) => {
  const { navStyle, pathname } = settings
  const { menuItems, loading } = menuSideBarSetting
  return { navStyle, pathname, menuItems, loading }
}
export default connect(mapStateToProps, { getMenuSettingsList, updateMenuSidebarList })(SidebarContent)
