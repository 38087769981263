import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import {onNavStyleChange, toggleCollapsedSideNav} from 'appRedux/actions/Setting'
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from '../../constants/ThemeSetting'


class SidebarLogo extends Component {

  render() {
    const {width, navCollapsed} = this.props
    let {navStyle} = this.props
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER
    }
    return (
      <div className="gx-layout-sider-header">

        <div className="gx-linebar">

        <i
          className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'}`}
          onClick={() => {
            if (navStyle === NAV_STYLE_DRAWER) {
              this.props.toggleCollapsedSideNav(!navCollapsed)
            } else if (navStyle === NAV_STYLE_FIXED) {
              this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR)
            } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
              this.props.toggleCollapsedSideNav(!navCollapsed)
            } else {
              this.props.onNavStyleChange(NAV_STYLE_FIXED)
            }
          }}
        />
        </div>

        <Link to="/" className="gx-site-logo">
          {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
            <img alt="" style={{ width: '15%' }} src={require('assets/images/logo1.png')}/> :
            <img alt="" style={{ width: '15%' }} src={require('assets/images/logo1.png')}/>}
            <h4
              style={{
                display: 'inline-block',
                color: 'white',
                marginLeft: '10px' }}
            >Bumblebee
            </h4>
        </Link>

      </div>
    )
  }
}

const mapStateToProps = ({settings}) => {
  const {navStyle, width, navCollapsed} = settings
  return {navStyle, width, navCollapsed}
}

export default connect(mapStateToProps, {
  onNavStyleChange,
  toggleCollapsedSideNav
})(SidebarLogo)

