import {
  GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES,
  GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES,
  GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES_SUCCESS,
} from '../../../constants/ActionTypes'

// Cancel Packages
export const getDashboardTop3PLCancelPackages = (payload) => (
  {
    type: GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES,
    payload
  }
)

export const getDashboardTop3PLCancelPackagesSuccess = (top3PLCancelPackages, totalNumberCancelPackages) => (
  {
    type: GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES_SUCCESS,
    payload: { top3PLCancelPackages, totalNumberCancelPackages }
  }
)

export const getDashboardHeaderTotalNumberCancelPackages = (payload) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES,
    payload
  }
)

export const getDashboardHeaderTotalNumberCancelPackagesSuccess = (headerTotalNumberCancelPackages) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES_SUCCESS,
    payload: headerTotalNumberCancelPackages
  }
)

// Error Cancel Packages
export const getDashboardTop3PLErrorCancelPackages = (payload) => (
  {
    type: GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES,
    payload
  }
)

export const getDashboardTop3PLErrorCancelPackagesSuccess = (top3PLErrorCancelPackages, totalNumberErrorCancelPackages) => (
  {
    type: GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES_SUCCESS,
    payload: { top3PLErrorCancelPackages, totalNumberErrorCancelPackages }
  }
)

export const getDashboardHeaderTotalNumberErrorCancelPackages = (payload) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES,
    payload
  }
)

export const getDashboardHeaderTotalNumberErrorCancelPackagesSuccess = (headerTotalNumberErrorCancelPackages) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES_SUCCESS,
    payload: headerTotalNumberErrorCancelPackages
  }
)
