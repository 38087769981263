import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {
  GET_DASHBOARD,
  GET_DASHBOARD_SYNC_TIME,
  EXPORT_DASHBOARD
} from '../../../constants/ActionTypes'
import {
  getDashboardSuccess,
  getDashboardSyncTimeSuccess,
  exportDashboardSuccess
} from '../../actions/Dashboards/Dashboard'

import { callApi } from '../../../util/api'
import { callApiGetFile } from "../../../util/api";

const fetchDashboardPackagesRequest = (payload) => callApi(
  '/api/v1.0/package/geterror',
  'GET',
  payload
)

const fetchDashboardSyncTimeRequest = (payload) => callApi(
  '/api/v1.0/package/getSyncTime',
  'GET',
  payload
)

const fetchDashboardExportRequest = (payload) => callApiGetFile(
  '/api/v1.0/package/exportData',
  'GET',
  payload
)

function* fetchDashboardExport({ payload }) {
  const fetchedCSV = yield call(fetchDashboardExportRequest, payload)
  yield put(exportDashboardSuccess(fetchedCSV))
}

export function* fetchDashboardExportWatcher() {
  yield takeEvery(EXPORT_DASHBOARD, fetchDashboardExport)
}

function* fetchDashboardPackages({ payload }) {
  const fetchedPackages = yield call(fetchDashboardPackagesRequest, payload)
  yield put(getDashboardSuccess(fetchedPackages))
}

export function* fetchDashboardPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD, fetchDashboardPackages)
}

function* fetchDashboardSyncTime({ payload }) {
  const fetchedSyncTime = yield call(fetchDashboardSyncTimeRequest, payload)
  yield put(getDashboardSyncTimeSuccess(fetchedSyncTime))
}

export function* fetchDashboardSyncTimeWatcher() {
  yield takeEvery(GET_DASHBOARD_SYNC_TIME, fetchDashboardSyncTime)
}

export default function* rootSaga() {
  yield all([
    fork(fetchDashboardPackagesWatcher),
    fork(fetchDashboardExportWatcher),
    fork(fetchDashboardSyncTimeWatcher)
  ])
}
