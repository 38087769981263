import {
  GET_PACKAGE_CANCELLATION_LOG,
  GET_PACKAGE_CANCELLATION_LOG_SUCCESS,
  SHOW_PACKAGE_CANCELLATION_MESSAGE,
  HIDE_PACKAGE_CANCELLATION_MESSAGE
} from "../../constants/ActionTypes";

const INIT_STATE = {
  packagesList: {},
  packageLogs: undefined,
  alertMessage: '',
  showMessage: false,
  loading: false
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

  case GET_PACKAGE_CANCELLATION_LOG:
    return {
      ...state,
      loading: true
    }

  case GET_PACKAGE_CANCELLATION_LOG_SUCCESS:
    return {
      ...state,
      loading: false,
      packagesList: action.payload
    }

  case SHOW_PACKAGE_CANCELLATION_MESSAGE:
    return {
      ...state,
      alertMessage: action.payload.toString(),
      showMessage: true,
      loading: false
    }

  case HIDE_PACKAGE_CANCELLATION_MESSAGE:
    return {
      ...state,
      alertMessage: '',
      showMessage: false,
      loading: false
    }

  default:
    return state
  }
}
