import {
  GET_PACKAGE_HISTORY,
  GET_PACKAGE_HISTORY_SUCCESS
} from '../../constants/ActionTypes'

export const getPackageHistory = (payload) => (
  {
    type: GET_PACKAGE_HISTORY,
    payload
  }
)

export const getPackageHistorySuccess = (packageHistoryList) => (
  {
    type: GET_PACKAGE_HISTORY_SUCCESS,
    payload: packageHistoryList
  }
)
