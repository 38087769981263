import {
  GET_OMS_API_DOCUMENT,
  GET_OMS_API_DOCUMENT_SUCCESS,
  GET_BUMBLEBEE_API_DOCUMENT,
  GET_BUMBLEBEE_API_DOCUMENT_SUCCESS,
  UPDATE_BUMBLEBEE_API_DOCUMENT_DATA,
  UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_SUCCESS,
  UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_FAIL, HIDE_MESSAGE,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  apiDocsList : {},
  loading: false,
  responseMessage: '',
  showMessage: false,
  success: true
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OMS_API_DOCUMENT:
      return {
        ...state,
        loading: true
      }

    case GET_OMS_API_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        apiDocsList: action.payload
      }

    case GET_BUMBLEBEE_API_DOCUMENT:
      return {
        ...state,
        loading: true
      }

    case GET_BUMBLEBEE_API_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        apiDocsList: action.payload
      }

    case UPDATE_BUMBLEBEE_API_DOCUMENT_DATA:
      return {
        ...state
      }
    case UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_SUCCESS:
      return {
        ...state,
        responseMessage: action.payload.message,
        showMessage: true,
        success: true
      }

    case UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_FAIL:
      return {
        ...state,
        responseMessage: action.payload.message,
        showMessage: true,
        success: false
      }

    case HIDE_MESSAGE:
      return {
        ...state,
        responseMessage: '',
        showMessage: false
      }
    default:
      return state
  }
}
