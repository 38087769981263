import {
  GET_MENU_LIST, GET_MENU_LIST_SUCCESS,
  UPDATE_MENU_LIST, UPDATE_MENU_LIST_SUCCESS
} from "../../constants/ActionTypes"

export const MenuState = { ACTIVE: 1, INACTIVE: 0 }
export const ALLOW_ALL_USERS_MENU_GROUP = ['Data Error and Sync', 'Mapping Status']
export const ADMIN_ONLY_MENU_GROUP = ['API Document']

// Make sure you add the new module [key] into database menu_settings
// Default new module added [is_active] = 1 (ACTIVE)

// Generate the ORDERED menu items on the sidebar (following to the order defines in the below menuItems list),
// But unordered at the menu settings dashboard,
// as menu items list was split into <SubMenu Navigation> and <Navigation>
// No need to worry about the "id" because you would fetch it from server
// to get and replace the id of the record.

// If the new module is sub menu item,
// add the Menu Group Name into 1 of the above array
// Also implement new "[] Navigation" at the MenuSidebarSettings UI page

// If the module is fixed (unallowed to be hidden) -> hidable: false
const menuItems = [
  {
    id_menu_settings: 0,
    key: 'dashboard',
    status: MenuState.INACTIVE,
    title: 'Dashboard',
    icon: 'icon icon-chart',
    routeLink: '/dashboard',
    hidable: true,
  },
  {
    id_menu_settings: 1,
    key: 'packages',
    status: MenuState.INACTIVE,
    title: 'Packages',
    icon: 'icon icon-widgets',
    routeLink: '/packages',
    hidable: true,
  },
  {
    id_menu_settings: 2,
    key: 'package-history',
    status: MenuState.INACTIVE,
    title: 'Packages History',
    icon: 'icon icon-timepicker',
    routeLink: '/package-history',
    hidable: true,
  },
  {
    id_menu_settings: 3,
    key: 'return-packages',
    status: MenuState.INACTIVE,
    title: 'Return Packages',
    icon: 'icon icon-reply',
    routeLink: '/return-packages',
    hidable: true,
  },
  {
    id_menu_settings: 4,
    key: 'hubcode',
    status: MenuState.INACTIVE,
    title: 'Hubcode',
    icon: 'icon icon-home',
    routeLink: '/hubcode',
    hidable: true,
  },
  {
    id_menu_settings: 5,
    key: 'queue-job',
    status: MenuState.INACTIVE,
    title: 'Queue Job',
    icon: 'icon icon-shuffle',
    routeLink: '/queue-job',
    hidable: true,
  },
  {
    id_menu_settings: 6,
    key: 'seller',
    status: MenuState.INACTIVE,
    title: '3PL Sellers',
    icon: 'icon icon-shopping-cart',
    routeLink: '/seller',
    hidable: true,
  },
  {
    id_menu_settings: 7,
    key: 'mapping-status',
    status: MenuState.INACTIVE,
    title: '3PL Mapping',
    icon: 'icon icon-edit',
    routeLink: '/mapping-status',
    hidable: true,
    subMenu: 'Mapping Status'
  },
  {
    id_menu_settings: 8,
    key: 'unmapped-status',
    status: MenuState.INACTIVE,
    title: 'Unmapped Statuses',
    icon: 'icon icon-editor',
    routeLink: '/unmapped-status',
    hidable: true,
    subMenu: 'Mapping Status' // add to the array MENU_GROUP
  },
  {
    id_menu_settings: 9,
    key: 'mp-seller-account',
    status: MenuState.INACTIVE,
    title: 'MP Seller Account',
    icon: 'icon icon-files',
    routeLink: '/mp-seller-account',
    hidable: true,
  },
  {
    id_menu_settings: 10,
    key: 'location-mapping',
    status: MenuState.INACTIVE,
    title: 'Location Mapping',
    icon: 'icon icon-files',
    routeLink: '/location-mapping',
    hidable: true,
  },
  {
    id_menu_settings: 11,
    key: 'dashboard-error',
    status: MenuState.INACTIVE,
    title: 'Dashboard Error',
    icon: 'icon icon-chart',
    routeLink: '/dashboard-error',
    hidable: true,
    subMenu: 'Data Error and Sync', // add to the array MENU_GROUP
  },
  {
    id_menu_settings: 12,
    key: 'dashboard-sync-time',
    status: MenuState.INACTIVE,
    title: 'Dashboard Sync Time',
    icon: 'icon icon-chart-line',
    routeLink: '/dashboard-sync-time',
    hidable: true,
    subMenu: 'Data Error and Sync',
  },
  {
    id_menu_settings: 13,
    key: 'oms-api',
    status: MenuState.INACTIVE,
    title: 'OMS API Document',
    icon: 'icon icon-company',
    routeLink: '/oms-api',
    hidable: true,
    subMenu: 'API Document', // add to the array MENU_GROUP
  },
  {
    id_menu_settings: 14,
    key: 'bumblebee-api',
    status: MenuState.INACTIVE,
    title: 'Bumblebee API Document',
    icon: 'icon icon-contacts',
    routeLink: '/bumblebee-api',
    hidable: true,
    subMenu: 'API Document',
  },
  {
    id_menu_settings: 15,
    key: 'shipment-provider',
    status: MenuState.INACTIVE,
    title: 'Shipment Provider',
    icon: 'icon icon-location',
    routeLink: '/shipment-provider',
    hidable: true,
  },
  {
    id_menu_settings: 16,
    key: 'cronjob',
    status: MenuState.INACTIVE,
    title: 'Cronjob',
    icon: 'icon icon-components',
    routeLink: '/cronjob',
    hidable: true,
  },
  {
    id_menu_settings: 17,
    key: 'error-data',
    status: MenuState.INACTIVE,
    title: 'Error Data',
    icon: 'icon icon-error',
    routeLink: '/error-data',
    hidable: true,
  },
  {
    id_menu_settings: 18,
    key: 'export-update-data',
    status: MenuState.INACTIVE,
    title: 'Export',
    icon: 'icon icon-files',
    routeLink: '/export-update-data',
    hidable: true,
  },
  {
    id_menu_settings: 19,
    key: 'user-management',
    status: MenuState.INACTIVE,
    title: 'User Last Login',
    icon: 'icon icon-user',
    routeLink: '/user-management',
    hidable: true,
  },
  {
    id_menu_settings: 20,
    key: 'menu-settings',
    status: MenuState.INACTIVE,
    title: 'Menu Settings',
    icon: 'icon icon-navigation',
    routeLink: '/menu-settings',
    hidable: false,
  },
]

const initialSettings = {
  loading: true,
  menuItems,
  error: false,
  message: ''
}

// Get and update fields of menuItems (id for updating, status: is_active)
const convertMenuFromDb = (menuSettingsFromDb) => {
  return menuItems.reduce((prev, item) => {
    const dbItem = menuSettingsFromDb.find(dbElem => item.key === dbElem.module)
    if (dbItem) {
      item.id_menu_settings = dbItem.id_menu_settings
      item.status = Number.isInteger(dbItem.is_active) ? dbItem.is_active : parseInt(dbItem.is_active)
      prev.push(item)
      return prev
    }
    return prev
  }, [])
}

export default (state = initialSettings, action) => {
  switch (action.type) {
    case GET_MENU_LIST:
      return {
        ...state,
        error: false,
        message: '',
        loading: true,
      }

    case GET_MENU_LIST_SUCCESS:
      const result = convertMenuFromDb(action.payload.data)
      return {
        ...state,
        message: '',
        loading: false,
        menuItems: result
      }
    case UPDATE_MENU_LIST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_MENU_LIST_SUCCESS:
      return {
        ...state,
        error: !action.payload.success,
        message: action.payload.message,
        loading: false,
      }

    default: // need this for default case
      return state

  }

}
