import {
  GET_OMS_API_DOCUMENT,
  GET_OMS_API_DOCUMENT_SUCCESS,
  GET_BUMBLEBEE_API_DOCUMENT,
  GET_BUMBLEBEE_API_DOCUMENT_SUCCESS,
  UPDATE_BUMBLEBEE_API_DOCUMENT_DATA,
  UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_SUCCESS,
  UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_FAIL, HIDE_MESSAGE,
} from '../../constants/ActionTypes'

export const getOmsApiDocument = (payload) => (
  {
    type: GET_OMS_API_DOCUMENT,
    payload
  }
)

export const getOmsApiDocumentSuccess = (apiDocsList) => (
  {
    type: GET_OMS_API_DOCUMENT_SUCCESS,
    payload: apiDocsList
  }
)

export const getBumblebeeApiDocument = (payload) => (
  {
    type: GET_BUMBLEBEE_API_DOCUMENT,
    payload
  }
)

export const getBumblebeeApiDocumentSuccess = (apiDocsList) => (
  {
    type: GET_BUMBLEBEE_API_DOCUMENT_SUCCESS,
    payload: apiDocsList
  }
)

export const updateBumblebeeApiDocumentData = (payload) => (
  {
    type: UPDATE_BUMBLEBEE_API_DOCUMENT_DATA,
    payload
  }
)

export const updateBumblebeeApiDocumentDataSuccess = (payload) => (
  {
    type: UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_SUCCESS,
    payload
  }
)

export const updateBumblebeeApiDocumentDataFail = (payload) => (
  {
    type: UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_FAIL,
    payload
  }
)

export const hideMessage = () => (
  {
    type: HIDE_MESSAGE,
  }
)
