import {
  GET_PROVIDERS,
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_BY_COUNTRY,
  GET_PROVIDERS_BY_COUNTRY_SUCCESS,
  GET_PROVIDER_SELLERS_BY_COUNTRY,
  GET_PROVIDER_SELLERS_BY_COUNTRY_SUCCESS,
  UPDATE_PROVIDER_BY_ID,
  UPDATE_PROVIDER_BY_ID_SUCCESS,
  UPDATE_PROVIDER_BY_ID_FAIL,
  GET_PROVIDERS_PAGINATION,
  GET_PROVIDERS_PAGINATION_SUCCESS
} from '../../constants/ActionTypes'

export const getProviders = (payload) => (
  {
    type: GET_PROVIDERS,
    payload
  }
)

export const getProvidersSuccess = (providersList) => (
  {
    type: GET_PROVIDERS_SUCCESS,
    payload: providersList
  }
)

export const getProvidersByCountry = (payload) => (
  {
    type: GET_PROVIDERS_BY_COUNTRY,
    payload
  }
)

export const getProvidersByCountrySuccess = (providersList) => (
  {
    type: GET_PROVIDERS_BY_COUNTRY_SUCCESS,
    payload: providersList
  }
)

export const getProviderSellersByCountry = (payload) => (
  {
    type: GET_PROVIDER_SELLERS_BY_COUNTRY,
    payload
  }
)

export const getProviderSellersByCountrySuccess = (providersList) => (
  {
    type: GET_PROVIDER_SELLERS_BY_COUNTRY_SUCCESS,
    payload: providersList
  }
)

export const updateProviderById = (payload) => (
  {
    type: UPDATE_PROVIDER_BY_ID,
    payload
  }
)

export const updateProviderByIdSuccess = (modifiedProvider) => (
  {
    type: UPDATE_PROVIDER_BY_ID_SUCCESS,
    payload: modifiedProvider
  }
)

export const updateProviderByIdFail = (error) => (
  {
    type: UPDATE_PROVIDER_BY_ID_FAIL,
    payload: error
  }
)

export const getProvidersPagination = (payload) => (
  {
    type: GET_PROVIDERS_PAGINATION,
    payload
  }
)

export const getProvidersPaginationSuccess = (pagination) => (
  {
    type: GET_PROVIDERS_PAGINATION_SUCCESS,
    payload: pagination
  }
)
