import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import { GET_QUEUE_JOBS } from '../../constants/ActionTypes'
import { getQueueJobsSuccess } from '../actions/QueueJobs'

import { callApi } from '../../util/api'

const fetchQueueJobsRequest = (payload) => callApi(
  '/api/v1.0/queue-job',
  'GET',
  payload
)

function* fetchQueueJobs({ payload }) {
  const fetchedQueueJobs = yield call(fetchQueueJobsRequest, payload)
  yield put(getQueueJobsSuccess(fetchedQueueJobs))
}

export function* fetchQueueJobsWatcher() {
  yield takeEvery(GET_QUEUE_JOBS, fetchQueueJobs)
}

export default function* rootSaga() {
  yield all([fork(fetchQueueJobsWatcher)])
}
