/* eslint-disable no-undef */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
  SIGNIN_GOOGLE_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  RESET_PASSWORD
} from 'constants/ActionTypes'
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  resetPasswordSuccess
} from '../../appRedux/actions/Auth'
import {
  userGoogleSignInSuccess
} from '../actions/Auth'

import { callApi } from '../../util/api'

export const ACCOUNT_TYPE = { GOOGLE: 'google', USERNAME: 'username' }

const signInUserWithEmailPasswordRequest = (payload) => callApi(
  '/api/v1.0/user/login',
  'POST',
  payload
)

const signInUserWithGoogleRequest = (payload) => callApi(
  '/api/v1.0/user/login',
  'POST',
  payload
)

const resetPasswordRequest = (payload) => callApi(
  '/api/v1.0/user/changePassword',
  'POST',
  payload
)

function* signInUserWithGoogle({ payload }) {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest, payload)
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message))
    } else {
      localStorage.setItem('api_key', signUpUser.token)
      localStorage.setItem('email', signUpUser.email)
      localStorage.setItem('account_type', ACCOUNT_TYPE.GOOGLE)
      yield put(userGoogleSignInSuccess(signUpUser.token))
    }
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}


function* signInUserWithEmailPassword({ payload }) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload)
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message))
    } else {
      localStorage.setItem('api_key', signInUser.token)
      localStorage.setItem('email', signInUser.email)
      localStorage.setItem('account_type', ACCOUNT_TYPE.USERNAME)
      yield put(userSignInSuccess(signInUser.token))
    }
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* resetPassword({ payload }) {
  try {
    const response = yield call(resetPasswordRequest, payload)
    if (response.message) {
      yield put(showAuthMessage(response.message))
    }
    yield put(resetPasswordSuccess(response))
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

function* signOut() {
  try {
    localStorage.removeItem('api_key')
    localStorage.removeItem('email')
    localStorage.removeItem('account_type')
    yield put(userSignOutSuccess(signOutUser))
  } catch (error) {
    yield put(showAuthMessage(error))
  }
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle)
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword)
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut)
}

export function* resetPasswordWatcher() {
  yield takeEvery(RESET_PASSWORD, resetPassword)
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signInWithGoogle),
    fork(signOutUser),
    fork(resetPasswordWatcher)
  ])
}
