import {
  GET_ERROR_DATA,
  GET_ERROR_DATA_SUCCESS
} from '../../constants/ActionTypes'

export const getErrorData = (payload) => (
  {
    type: GET_ERROR_DATA,
    payload
  }
)

export const getErrorDataSuccess = (errorDataList) => (
  {
    type: GET_ERROR_DATA_SUCCESS,
    payload: errorDataList
  }
)
