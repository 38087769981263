import {
  GET_CRONJOB_STATUS,
  GET_CRONJOB_STATUS_SUCCESS,
  TRIGGER_CRONJOB,
  TRIGGER_CRONJOB_SUCCESS,
  TRIGGER_CRONJOB_FAIL, HIDE_MESSAGE, SHOW_LOG_DATA, HIDE_LOG_DATA
} from '../../constants/ActionTypes'

export const getCronjobStatus = (payload) => (
  {
    type: GET_CRONJOB_STATUS,
    payload
  }
)

export const getCronjobStatusSuccess = (payload) => (
  {
    type: GET_CRONJOB_STATUS_SUCCESS,
    payload
  }
)

export const triggerCronjob = (payload) => (
  {
    type: TRIGGER_CRONJOB,
    payload
  }
)

export const triggerCronjobSuccess = (payload) => (
  {
    type: TRIGGER_CRONJOB_SUCCESS,
    payload
  }
)

export const triggerCronjobFail = (payload) => (
  {
    type: TRIGGER_CRONJOB_FAIL,
    payload
  }
)

export const hideMessage = (payload) => (
  {
    type: HIDE_MESSAGE,
    payload
  }
)

export const showLogData = (payload) => (
  {
    type: SHOW_LOG_DATA,
    payload
  }
)

export const hideLogData = (payload) => (
  {
    type: HIDE_LOG_DATA,
    payload
  }
)
