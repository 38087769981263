import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {
  GET_DASHBOARD_TOP_3PL_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES,
  GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES
} from '../../../constants/ActionTypes'
import {
  getDashboardTop3PLPackagesSuccess,
  getDashboardHeaderTotalNumberPackagesSuccess,
  getDashboardTop3PLErrorPackagesSuccess,
  getDashboardHeaderTotalNumberErrorPackagesSuccess,
  getDashboardTop3PLLiveTrackingErrorPackagesSuccess,
  getDashboardHeaderTotalNumberLiveTrackingErrorPackagesSuccess
} from '../../actions/Dashboards/Packages'

import { callApi } from '../../../util/api'

// Packages
const fetchDashboardTop3PLPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/top3PLWithNumberOfPackages",
  "GET",
  payload
)

function* fetchDashboardTop3PLPackages({ payload }) {
  const fetched3PLPackages = yield call(fetchDashboardTop3PLPackagesRequest, payload);
  const fetchedTotalNumberPackages = yield call(fetchDashboardTotalNumberPackagesRequest, payload);
  yield put(getDashboardTop3PLPackagesSuccess(fetched3PLPackages, fetchedTotalNumberPackages));
}

export function* fetchDashboardTop3PLPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_3PL_PACKAGES, fetchDashboardTop3PLPackages);
}

const fetchDashboardTotalNumberPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/totalNumberOfPackages",
  "GET",
  payload
)

function* fetchDashboardHeaderTotalNumberPackages({ payload }) {
  const fetchedHeaderTotalNumberPackages = yield call(fetchDashboardTotalNumberPackagesRequest, payload);
  yield put(getDashboardHeaderTotalNumberPackagesSuccess(fetchedHeaderTotalNumberPackages));
}

export function* fetchDashboardHeaderTotalNumberPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES, fetchDashboardHeaderTotalNumberPackages);
}

// Error Packages
const fetchDashboardTop3PLErrorPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/top3PLWithNumberOfShippingErrors",
  "GET",
  payload
)

function* fetchDashboardTop3PLErrorPackages({ payload }) {
  const fetched3PLErrorPackages = yield call(fetchDashboardTop3PLErrorPackagesRequest, payload);
  const fetchedTotalNumberErrorPackages = yield call(fetchDashboardTotalNumberErrorPackagesRequest, payload);
  yield put(getDashboardTop3PLErrorPackagesSuccess(fetched3PLErrorPackages, fetchedTotalNumberErrorPackages));
}

const fetchDashboardTotalNumberErrorPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/totalNumberOfShippingErrors",
  "GET",
  payload
)

export function* fetchDashboardTop3PLErrorPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES, fetchDashboardTop3PLErrorPackages);
}

function* fetchDashboardHeaderTotalNumberErrorPackages({ payload }) {
  const fetchedHeaderTotalNumberErrorPackages = yield call(fetchDashboardTotalNumberErrorPackagesRequest, payload);
  yield put(getDashboardHeaderTotalNumberErrorPackagesSuccess(fetchedHeaderTotalNumberErrorPackages));
}

export function* fetchDashboardHeaderTotalNumberErrorPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES, fetchDashboardHeaderTotalNumberErrorPackages);
}

// Live Tracking Error Packages
const fetchDashboardTop3PLLiveTrackingErrorPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/top3PLWithNumberOfTrackingErrors",
  "GET",
  payload
)

function* fetchDashboardTop3PLLiveTrackingErrorPackages({ payload }) {
  const fetched3PLLiveTrackingErrorPackages = yield call(fetchDashboardTop3PLLiveTrackingErrorPackagesRequest, payload);
  const fetchedTotalNumberLiveTrackingErrorPackages = yield call(fetchDashboardTotalNumberLiveTrackingErrorPackagesRequest, payload);
  yield put(getDashboardTop3PLLiveTrackingErrorPackagesSuccess(fetched3PLLiveTrackingErrorPackages, fetchedTotalNumberLiveTrackingErrorPackages));
}

export function* fetchDashboardTop3PLLiveTrackingErrorPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES, fetchDashboardTop3PLLiveTrackingErrorPackages);
}

const fetchDashboardTotalNumberLiveTrackingErrorPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/totalNumberOfTrackingErrors",
  "GET",
  payload
)

function* fetchDashboardHeaderTotalNumberLiveTrackingErrorPackages({ payload }) {
  const fetchedHeaderTotalNumberLiveTrackingErrorPackages = yield call(fetchDashboardTotalNumberLiveTrackingErrorPackagesRequest, payload);
  yield put(getDashboardHeaderTotalNumberLiveTrackingErrorPackagesSuccess(fetchedHeaderTotalNumberLiveTrackingErrorPackages));
}

export function* fetchDashboardHeaderTotalNumberLiveTrackingErrorPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES, fetchDashboardHeaderTotalNumberLiveTrackingErrorPackages);
}


export default function* rootSaga() {
  yield all([
    fork(fetchDashboardTop3PLPackagesWatcher),
    fork(fetchDashboardHeaderTotalNumberPackagesWatcher),
    fork(fetchDashboardTop3PLErrorPackagesWatcher),
    fork(fetchDashboardHeaderTotalNumberErrorPackagesWatcher),
    fork(fetchDashboardTop3PLLiveTrackingErrorPackagesWatcher),
    fork(fetchDashboardHeaderTotalNumberLiveTrackingErrorPackagesWatcher)
  ])
}
