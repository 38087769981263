import {
  GET_MENU_LIST, GET_MENU_LIST_SUCCESS,
  UPDATE_MENU_LIST, UPDATE_MENU_LIST_SUCCESS
} from "../../constants/ActionTypes"

export const getMenuSettingsList = (payload) => {
  return {
    type: GET_MENU_LIST,
    payload,
  }
}

export const getMenuSettingsListSuccess = (payload) => {
  return {
    type: GET_MENU_LIST_SUCCESS,
    payload,
  }
}

export const updateMenuSidebarList = (payload) => {
  return {
    type: UPDATE_MENU_LIST,
    payload,
  }
}

export const updateMenuSidebarListSuccess = (payload) => {
  return {
    type: UPDATE_MENU_LIST_SUCCESS,
    payload,
  }
}
