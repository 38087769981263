import {
  EDIT_3PL_SELLER,
  EDIT_3PL_SELLER_SUCCESS,
  GET_3PL_SELLER_DETAIL,
  GET_3PL_SELLER_DETAIL_SUCCESS,
  GET_3PL_SELLER,
  GET_3PL_SELLER_SUCCESS,
  SHOW_MESSAGE_3PL_SELLER,
  HIDE_MESSAGE_3PL_SELLER,
  EDIT_3PL_SELLER_FAILED
} from '../../constants/ActionTypes'

const INIT_STATE = {
  sellerList: [],
  loading: false,
  selectedSeller: {},
  edited3PLSeller: undefined,
  isFail: false
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

  case GET_3PL_SELLER:
    return {
      ...state,
      loading: true
    }

  case GET_3PL_SELLER_SUCCESS:
    return {
      ...state,
      loading: false,
      sellerList: action.payload
    }

  case EDIT_3PL_SELLER:
    return {
      ...state,
      loading: true
    }

  case EDIT_3PL_SELLER_SUCCESS:
    return {
      ...state,
      loading: false,
      edited3PLSeller: action.payload
    }
  case GET_3PL_SELLER_DETAIL:
    return {
      ...state,
      loading: true
    }

  case GET_3PL_SELLER_DETAIL_SUCCESS:
    return {
      ...state,
      loading: false,
      selectedSeller: action.payload
    }

  case SHOW_MESSAGE_3PL_SELLER: {
    return {
      ...state,
      alertMessage: action.payload,
      showMessage: true,
      loading: false
    }
  }

  case HIDE_MESSAGE_3PL_SELLER: {
    return {
      ...state,
      alertMessage: '',
      showMessage: false,
      loading: false,
      isFail: false
    }
  }

  case EDIT_3PL_SELLER_FAILED: {
    return {
      ...state,
      alertMessage: action.payload,
      isFail: true
    }
  }

  default:
    return state
  }
}
