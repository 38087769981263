import {
  GET_LOCATION_MAPPING_TABLE,
  GET_LOCATION_MAPPING_TABLE_SUCCESS,
  GET_LOCATION_MAPPING,
  GET_LOCATION_MAPPING_SUCCESS,
  EDIT_LOCATION_MAPPING,
  EDIT_LOCATION_MAPPING_SUCCESS,
  EDIT_LOCATION_MAPPING_FAILED,
  ADD_LOCATION_MAPPING,
  ADD_LOCATION_MAPPING_SUCCESS,
  ADD_LOCATION_MAPPING_FAILED,
  DELETE_LOCATION_MAPPING,
  DELETE_LOCATION_MAPPING_SUCCESS,
  DELETE_LOCATION_MAPPING_FAILED,
  IMPORT_LOCATION_MAPPING,
  IMPORT_LOCATION_MAPPING_SUCCESS,
  IMPORT_LOCATION_MAPPING_FAILED,
  EXPORT_LOCATION_MAPPING,
  EXPORT_LOCATION_MAPPING_SUCCESS,
} from '../../constants/ActionTypes'

export const getLocationMappingTable = (payload) => (
  {
    type: GET_LOCATION_MAPPING_TABLE,
    payload
  }
)

export const getLocationMappingTableSuccess = (locationTableList) => (
  {
    type: GET_LOCATION_MAPPING_TABLE_SUCCESS,
    payload: locationTableList
  }
)

export const getLocationMapping = (payload) => (
  {
    type: GET_LOCATION_MAPPING,
    payload
  }
)

export const getLocationMappingSuccess = (locationMappingList) => (
  {
    type: GET_LOCATION_MAPPING_SUCCESS,
    payload: locationMappingList
  }
)

export const editLocationMapping = (payload) => (
  {
    type: EDIT_LOCATION_MAPPING,
    payload
  }
)

export const editLocationMappingSuccess = (message) => (
  {
    type: EDIT_LOCATION_MAPPING_SUCCESS,
    payload: message
  }
)

export const editLocationMappingFailed = (message) => {
  return {
    type: EDIT_LOCATION_MAPPING_FAILED,
    payload: message
  }
}

export const addLocationMapping = (payload) => (
  {
    type: ADD_LOCATION_MAPPING,
    payload
  }
)

export const addLocationMappingSuccess = payload => (
  {
    type: ADD_LOCATION_MAPPING_SUCCESS,
    payload
  }
)

export const addLocationMappingFailed = payload => {
  return {
    type: ADD_LOCATION_MAPPING_FAILED,
    payload
  }
}

export const deleteLocationMapping = (payload) => (
  {
    type: DELETE_LOCATION_MAPPING,
    payload
  }
)

export const deleteLocationMappingSuccess = payload => (
  {
    type: DELETE_LOCATION_MAPPING_SUCCESS,
    payload
  }
)

export const deleteLocationMappingFailed = payload => (
  {
    type: DELETE_LOCATION_MAPPING_FAILED,
    payload
  }
)
export const importLocationMapping = (payload) => (
  {
    type: IMPORT_LOCATION_MAPPING,
    payload
  }
)

export const importLocationMappingSuccess = (payload) => (
  {
    type: IMPORT_LOCATION_MAPPING_SUCCESS,
    payload
  }
)

export const importLocationMappingFailed = (payload) => (
  {
    type: IMPORT_LOCATION_MAPPING_FAILED,
    payload
  }
)

export const exportLocationMapping = (payload) => (
  {
      type: EXPORT_LOCATION_MAPPING,
      payload
  }
)

export const exportLocationMappingSuccess = (payload) => (
  {
      type: EXPORT_LOCATION_MAPPING_SUCCESS,
      payload
  }
)
