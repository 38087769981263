import {
  GET_MP_SELLER,
  GET_MP_SELLER_SUCCESS,
  EDIT_MP_SELLER,
  EDIT_MP_SELLER_SUCCESS,
  EDIT_MP_SELLER_FAILED,
  ADD_MP_SELLER,
  ADD_MP_SELLER_SUCCESS,
  ADD_MP_SELLER_FAILED,
  DELETE_MP_SELLER,
  DELETE_MP_SELLER_SUCCESS,
  DELETE_MP_SELLER_FAILED,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  mpSellerList: {},
  isToReload: false,
  isSuccess: undefined,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_MP_SELLER:
      return {
        ...state,
        loading: true
      }

    case GET_MP_SELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        isToReload: false,
        mpSellerList: action.payload
      }

    case EDIT_MP_SELLER:
    case ADD_MP_SELLER:
    case DELETE_MP_SELLER:
      return {
        ...state,
        loading: true,
      }

    case EDIT_MP_SELLER_SUCCESS:
    case ADD_MP_SELLER_SUCCESS:
    case DELETE_MP_SELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        isToReload: true,
        message: action.payload.message,
        isSuccess: action.payload.isSuccess
      }

    case EDIT_MP_SELLER_FAILED:
    case ADD_MP_SELLER_FAILED:
    case DELETE_MP_SELLER_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.isSuccess
      }

    default:
      return state
  }
}
