import {
  GET_USERS_LIST, GET_USERS_LIST_SUCCESS,
  UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL
} from '../../constants/ActionTypes'

const INIT_STATE = {
  usersList: {},
  loading: false,
  message: '',
  error: false,
  shouldReload: false
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_USERS_LIST:
      return {
        ...state,
        message: '',
        error: false,
        loading: true
      }

    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        usersList: action.payload,
        shouldReload: false
      }

    case UPDATE_USER:
      return {
        ...state,
        loading: true,
        shouldReload: false
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        error: false,
        message: action.payload.message,
        loading: false,
        shouldReload: true
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: true,
        message: action.payload.message,
        loading: false,
        shouldReload: true
      }

    default:
      return state
  }
}
