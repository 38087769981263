import { all } from 'redux-saga/effects'
import packagesSagas from './Packages'
import hubcodeSagas from './Hubcode'
import queueJobsSagas from './QueueJobs'
import authSagas from './Auth'
import sellerSagas from './Seller3PL'
import providerSagas from './Provider'
import returnPackageSagas from './ReturnPackages'
import dashboardSagas from './Dashboards/Dashboard'
import dashboardPackagesSagas from './Dashboards/Packages'
import dashboardReturnPackagesSagas from './Dashboards/ReturnPackages'
import dashboardCancelPackagesSagas from './Dashboards/CancelPackages'
import dashboardUserLoginSagas from './Dashboards/UserLogin'
import mappingStatusSagas from './MappingStatus'
import apiDocsSagas from './ApiDocs'
import cronjobSagas from './Cronjob'
import liveTrackingReturnPackagesSagas from './LiveTrackingReturnPackages'
import PackageHistorySagas from './PackageHistory'
import unmappedStatusSagas from './UnmappedStatus'
import errorDataSagas from './ErrorData'
import userManagementSagas from './UserManagement'
import MpSellerAccountSagas from './MpSellerAccount'
import locationMappingSagas from './LocationMapping'
import exportUpdateDataSagas from './ExportUpdateData'
import menuSidebarSettingSagas from './MenuSidebarSettings'


export default function* rootSaga(getState) {
  yield all([
    locationMappingSagas(),
    packagesSagas(),
    hubcodeSagas(),
    queueJobsSagas(),
    authSagas(),
    sellerSagas(),
    providerSagas(),
    returnPackageSagas(),
    dashboardSagas(),
    dashboardPackagesSagas(),
    dashboardReturnPackagesSagas(),
    dashboardCancelPackagesSagas(),
    dashboardUserLoginSagas(),
    mappingStatusSagas(),
    MpSellerAccountSagas(),
    apiDocsSagas(),
    cronjobSagas(),
    liveTrackingReturnPackagesSagas(),
    PackageHistorySagas(),
    unmappedStatusSagas(),
    errorDataSagas(),
    userManagementSagas(),
    exportUpdateDataSagas(),
    menuSidebarSettingSagas()
  ])
}
