import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {
  GET_HUB_CODE,
  GET_HUB_CODE_DETAIL,
  DELETE_HUB_CODE,
  EDIT_HUB_CODE,
  IMPORT_HUB_CODE
} from '../../constants/ActionTypes'
import {
  getHubcodeSuccess,
  getHubcodeDetailSuccess,
  deleteHubcodeSuccess,
  editHubcodeSuccess,
  showMessage
} from '../actions/Hubcode'

import { callApi } from '../../util/api'

const fetchHubcodeRequest = (payload) => callApi(
  '/api/v1.0/hubcode',
  'GET',
  payload
)

function* fetchHubcode({ payload }) {
  const fetchedHubcode = yield call(fetchHubcodeRequest, payload)
  yield put(getHubcodeSuccess(fetchedHubcode))
}

export function* fetchHubcodeWatcher() {
  yield takeEvery(GET_HUB_CODE, fetchHubcode)
}

const fetchHubcodeDetailRequest = (payload) => callApi(
  '/api/v1.0/hubcode/get',
  'GET',
  payload
)

function* fetchHubcodeDetail({ payload }) {
  const fetchedHubcodeDetail = yield call(fetchHubcodeDetailRequest, payload)
  yield put(getHubcodeDetailSuccess(fetchedHubcodeDetail))
}

export function* fetchHubcodeDetailWatcher() {
  yield takeEvery(GET_HUB_CODE_DETAIL, fetchHubcodeDetail)
}

const deleteHubcodeRequest = (payload) => callApi(
  '/api/v1.0/hubcode/delete',
  'DELETE',
  payload
)

function* deleteHubcode({ payload }) {
  try {
    const deletedHubcode = yield call(deleteHubcodeRequest, payload)
    yield put(showMessage('Your hubcode has been deleted successfully'))
    yield put(deleteHubcodeSuccess(deletedHubcode))
  } catch (error) {
    yield put(showMessage(error))
  }
}

export function* deleteHubcodeWatcher() {
  yield takeEvery(DELETE_HUB_CODE, deleteHubcode)
}

const editHubcodeRequest = (payload) => callApi(
  '/api/v1.0/hubcode/update',
  'PUT',
  payload
)

function* editHubcode({ payload }) {
  try {
    const editedHubcode = yield call(editHubcodeRequest, payload)
    yield put(showMessage('Your hubcode has been edited successfully'))
    yield put(editHubcodeSuccess(editedHubcode))
  } catch (error) {
    yield put(showMessage(error))
  }
}

export function* editHubcodeWatcher() {
  yield takeEvery(EDIT_HUB_CODE, editHubcode)
}

const importHubcodeRequest = (payload) => callApi(
  '/api/v1.0/file-import/import',
  'POST',
  payload,
  'upload'
)

function* importHubcode({ payload }) {
  try {
    yield call(importHubcodeRequest, payload)
    yield put(showMessage('Success'))
  } catch (error) {
    yield put(showMessage(error ? "Unsuccessful" : error.toString()))
  }
}

export function* importHubcodeWatcher() {
  yield takeEvery(IMPORT_HUB_CODE, importHubcode)
}

export default function* rootSaga() {
  yield all([
    fork(fetchHubcodeWatcher),
    fork(fetchHubcodeDetailWatcher),
    fork(deleteHubcodeWatcher),
    fork(editHubcodeWatcher),
    fork(importHubcodeWatcher)
  ])
}
