import {
  GET_DASHBOARD_TOP_USERS_LOGIN,
  GET_DASHBOARD_TOP_USERS_LOGIN_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN_SUCCESS,
} from '../../../constants/ActionTypes'

// User Login
export const getDashboardTopUserLogin = (payload) => (
  {
    type: GET_DASHBOARD_TOP_USERS_LOGIN,
    payload
  }
)

export const getDashboardTopUserLoginSuccess = (topUserLoginList, totalNumberUserLogin) => (
  {
    type: GET_DASHBOARD_TOP_USERS_LOGIN_SUCCESS,
    payload: { topUserLoginList, totalNumberUserLogin }
  }
)

export const getDashboardHeaderTotalNumberUserLogin = (payload) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN,
    payload
  }
)

export const getDashboardHeaderTotalNumberUserLoginSuccess = (headerTotalNumberUserLogin) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN_SUCCESS,
    payload: headerTotalNumberUserLogin
  }
)
