import {
  GET_QUEUE_JOBS,
  GET_QUEUE_JOBS_SUCCESS
} from '../../constants/ActionTypes'

export const getQueueJobs = (payload) => (
  {
    type: GET_QUEUE_JOBS,
    payload
  }
)

export const getQueueJobsSuccess = (queueJobsList) => (
  {
    type: GET_QUEUE_JOBS_SUCCESS,
    payload: queueJobsList
  }
)
