import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {
  GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES,
  GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES,
} from '../../../constants/ActionTypes'
import {
  getDashboardTop3PLReturnPackagesSuccess,
  getDashboardHeaderTotalNumberReturnPackagesSuccess,
  getDashboardTop3PLErrorReturnPackagesSuccess,
  getDashboardHeaderTotalNumberErrorReturnPackagesSuccess,
  getDashboardTop3PLLiveTrackingErrorReturnPackagesSuccess,
  getDashboardHeaderTotalNumberLiveTrackingErrorReturnPackagesSuccess,
} from '../../actions/Dashboards/ReturnPackages'

import { callApi } from '../../../util/api'

// Return Packages
const fetchDashboardTop3PLReturnPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/top3PLWithNumberOfReturnPackages",
  "GET",
  payload
)

function* fetchDashboardTop3PLReturnPackages({ payload }) {
  const fetched3PLReturnPackages = yield call(fetchDashboardTop3PLReturnPackagesRequest, payload);
  const fetchedTotalNumberReturnPackages = yield call(fetchDashboardTotalNumberReturnPackagesRequest, payload);
  yield put(getDashboardTop3PLReturnPackagesSuccess(fetched3PLReturnPackages, fetchedTotalNumberReturnPackages));
}

export function* fetchDashboardTop3PLReturnPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES, fetchDashboardTop3PLReturnPackages);
}

const fetchDashboardTotalNumberReturnPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/totalNumberOfReturnPackages",
  "GET",
  payload
)

function* fetchDashboardHeaderTotalNumberReturnPackages({ payload }) {
  const fetchedHeaderTotalNumberReturnPackages = yield call(fetchDashboardTotalNumberReturnPackagesRequest, payload);
  yield put(getDashboardHeaderTotalNumberReturnPackagesSuccess(fetchedHeaderTotalNumberReturnPackages));
}

export function* fetchDashboardHeaderTotalNumberReturnPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES, fetchDashboardHeaderTotalNumberReturnPackages);
}

// Error Return Packages
const fetchDashboardTop3PLErrorReturnPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/top3PLWithNumberOfReturnErrors",
  "GET",
  payload
)

function* fetchDashboardTop3PLErrorReturnPackages({ payload }) {
  const fetched3PLErrorReturnPackages = yield call(fetchDashboardTop3PLErrorReturnPackagesRequest, payload);
  const fetchedTotalNumberErrorReturnPackages = yield call(fetchDashboardTotalNumberErrorReturnPackagesRequest, payload);
  yield put(getDashboardTop3PLErrorReturnPackagesSuccess(fetched3PLErrorReturnPackages, fetchedTotalNumberErrorReturnPackages));
}

export function* fetchDashboardTop3PLErrorReturnPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES, fetchDashboardTop3PLErrorReturnPackages);
}

const fetchDashboardTotalNumberErrorReturnPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/totalNumberOfReturnErrors",
  "GET",
  payload
)

function* fetchDashboardHeaderTotalNumberErrorReturnPackages({ payload }) {
  const fetchedHeaderTotalNumberErrorReturnPackages = yield call(fetchDashboardTotalNumberErrorReturnPackagesRequest, payload);
  yield put(getDashboardHeaderTotalNumberErrorReturnPackagesSuccess(fetchedHeaderTotalNumberErrorReturnPackages));
}

export function* fetchDashboardHeaderTotalNumberErrorReturnPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES, fetchDashboardHeaderTotalNumberErrorReturnPackages);
}

// Live Tracking Error Return Packages
const fetchDashboardTop3PLLiveTrackingErrorReturnPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/top3PLWithNumberOfReturnTrackingErrors",
  "GET",
  payload
)

function* fetchDashboardTop3PLLiveTrackingErrorReturnPackages({ payload }) {
  const fetched3PLLiveTrackingErrorReturnPackages = yield call(fetchDashboardTop3PLLiveTrackingErrorReturnPackagesRequest, payload);
  const fetchedTotalNumberLiveTrackingErrorReturnPackages = yield call(fetchDashboardTotalNumberLiveTrackingErrorReturnPackagesRequest, payload);
  yield put(getDashboardTop3PLLiveTrackingErrorReturnPackagesSuccess(fetched3PLLiveTrackingErrorReturnPackages, fetchedTotalNumberLiveTrackingErrorReturnPackages));
}

export function* fetchDashboardTop3PLLiveTrackingErrorReturnPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES, fetchDashboardTop3PLLiveTrackingErrorReturnPackages);
}

const fetchDashboardTotalNumberLiveTrackingErrorReturnPackagesRequest = (payload) => callApi(
  "/api/v1.0/dashboard/totalNumberOfReturnTrackingErrors",
  "GET",
  payload
)

function* fetchDashboardHeaderTotalNumberLiveTrackingErrorReturnPackages({ payload }) {
  const fetchedHeaderTotalNumberLiveTrackingErrorReturnPackages = yield call(fetchDashboardTotalNumberLiveTrackingErrorReturnPackagesRequest, payload);
  yield put(getDashboardHeaderTotalNumberLiveTrackingErrorReturnPackagesSuccess(fetchedHeaderTotalNumberLiveTrackingErrorReturnPackages));
}

export function* fetchDashboardHeaderTotalNumberLiveTrackingErrorReturnPackagesWatcher() {
  yield takeEvery(GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES, fetchDashboardHeaderTotalNumberLiveTrackingErrorReturnPackages);
}

export default function* rootSaga() {
  yield all([
    fork(fetchDashboardTop3PLReturnPackagesWatcher),
    fork(fetchDashboardHeaderTotalNumberReturnPackagesWatcher),
    fork(fetchDashboardTop3PLErrorReturnPackagesWatcher),
    fork(fetchDashboardHeaderTotalNumberErrorReturnPackagesWatcher),
    fork(fetchDashboardTop3PLLiveTrackingErrorReturnPackagesWatcher),
    fork(fetchDashboardHeaderTotalNumberLiveTrackingErrorReturnPackagesWatcher),
  ])
}
