import { takeEvery, call, all, fork, put } from 'redux-saga/effects'

import {
  GET_MP_SELLER,
  EDIT_MP_SELLER,
  ADD_MP_SELLER,
  DELETE_MP_SELLER,
} from '../../constants/ActionTypes'
import {
  getMpSellerSuccess,
  editMpSellerSuccess,
  editMpSellerFailed,
  addMpSellerFailed,
  addMpSellerSuccess,
  deleteMpSellerFailed,
  deleteMpSellerSuccess,
} from '../actions/MpSellerAccount'

import { callApi, callApiGetFile } from '../../util/api'

const fetchMpSellerRequest = (payload) => callApi(
  '/api/v1.0/seller-account',
  'GET',
  payload
)

function* fetchMpSeller({ payload }) {
  const res = yield call(fetchMpSellerRequest, payload)
  yield put(getMpSellerSuccess(res))
}

export function* getMpSellerWatcher() {
  yield takeEvery(GET_MP_SELLER, fetchMpSeller)
}

const editMpSellerRequest = (payload) => callApi(
  '/api/v1.0/seller-account/edit',
  'PUT',
  payload
)

function* editMpSeller({ payload }) {
  const res = yield call(editMpSellerRequest, payload)
  res && res.isSuccess ? yield put(editMpSellerSuccess(res))
    : put(editMpSellerFailed(res))
}

export function* editMpSellerWatcher() {
  yield takeEvery(EDIT_MP_SELLER, editMpSeller)
}

const addMpSellerRequest = (payload) => callApi(
  '/api/v1.0/seller-account/add',
  'POST',
  payload
)

function* addMpSeller({ payload }) {
  const res = yield call(addMpSellerRequest, payload)
  res && res.isSuccess ? yield put(addMpSellerSuccess(res))
    : put(addMpSellerFailed(res))
}

export function* addMpSellerWatcher() {
  yield takeEvery(ADD_MP_SELLER, addMpSeller)
}

const deleteMpSellerRequest = (payload) => callApi(
  '/api/v1.0/seller-account/delete',
  'POST',
  payload
)

function* deleteMpSeller({ payload }) {
  const res = yield call(deleteMpSellerRequest, payload)
  res && res.isSuccess ? yield put(deleteMpSellerSuccess(res))
    : yield put(deleteMpSellerFailed(res))
}

export function* deleteMpSellerWatcher() {
  yield takeEvery(DELETE_MP_SELLER, deleteMpSeller)
}

export default function* rootSaga() {
  yield all([
    fork(getMpSellerWatcher),
    fork(editMpSellerWatcher),
    fork(addMpSellerWatcher),
    fork(deleteMpSellerWatcher),
  ])
}







