import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {EDIT_3PL_SELLER, GET_3PL_SELLER, GET_3PL_SELLER_DETAIL} from '../../constants/ActionTypes'
import {
  getSeller3PLSuccess,
  edit3PLSellerSuccess,
  getSellerDetailSuccess,
  showMessage,
  edit3PLSellerFailed
} from '../actions/Seller3PL'

import { callApi } from '../../util/api'

const fetchSeller3PLRequest = (payload) => callApi(
  '/api/v1.0/seller',
  'GET',
  payload
)

function* fetchSeller({ payload }) {
  const fetchedPackages = yield call(fetchSeller3PLRequest, payload)
  yield put(getSeller3PLSuccess(fetchedPackages))
}

export function* fetchSellerWatcher() {
  yield takeEvery(GET_3PL_SELLER, fetchSeller)
}

const edit3PLSellerRequest = (payload) => callApi(
  '/api/v1.0/seller/update',
  'PUT',
  payload
)

function* edit3PLSeller({ payload }) {
  try {
    const edit3PLSeller = yield call(edit3PLSellerRequest, payload)
    if (edit3PLSeller.success === false)
    {
      const data  =  edit3PLSeller.data
      yield put(edit3PLSellerFailed(
        'Inserted 3PL Account Number is already associate with another Seller: '+ data[0].name +'. Please use a unique 3PL Account Number and try again!'
      ))
      yield put(edit3PLSellerSuccess(edit3PLSeller))

    }
    else {
      yield put(showMessage('Your 3PL-Seller has been edited successfully'))
      yield put(edit3PLSellerSuccess(edit3PLSeller))
    }
  } catch (error) {
    yield put(showMessage(error))
  }
}

export function* edit3PLSellerWatcher() {
  yield takeEvery(EDIT_3PL_SELLER, edit3PLSeller)
}

const fetchSellerDetailRequest = (payload) => callApi(
  '/api/v1.0/seller/get',
  'GET',
  payload
)

function* fetchSellerDetail({ payload }) {
  const fetchedSellerDetail = yield call(fetchSellerDetailRequest, payload)
  yield put(getSellerDetailSuccess(fetchedSellerDetail))
}

export function* fetchSellerDetailWatcher() {
  yield takeEvery(GET_3PL_SELLER_DETAIL, fetchSellerDetail)
}
export default function* rootSaga() {
  yield all([
    fork(fetchSellerWatcher),
    fork(edit3PLSellerWatcher),
    fork(fetchSellerDetailWatcher)

  ])
}
