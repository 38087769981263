import { takeEvery, call, all, fork, put } from 'redux-saga/effects'

import {
  GET_MAPPING_STATUS,
  EDIT_MAPPING_STATUS,
  EDIT_CUSTOM_DATA_FIELDS,
  GET_BUMBLEBEE_STATUS_LIST,
  ADD_MAPPING_STATUS,
  DELETE_MAPPING_STATUS,
  IMPORT_MAPPING_STATUS,
  DOWNLOAD_EXAMPLE,
  EXPORT_MAPPING_STATUS,
  GET_BUMBLEBEE_FORWARD_STATUS,
  GET_BUMBLEBEE_RETURN_STATUS
} from '../../constants/ActionTypes'
import {
  getMappingStatusSuccess,
  editMappingStatusSuccess,
  editMappingStatusFailed,
  editCustomDataFieldsSuccess,
  editCustomDataFieldsFailed,
  getBumblebeeStatusListSuccess,
  addMappingStatusFailed,
  addMappingStatusSuccess,
  deleteMappingStatusFailed,
  deleteMappingStatusSuccess,
  importMappingStatusFailed,
  importMappingStatusSuccess,
  downloadExampleSuccess,
  exportMappingStatusSuccess,
  getBumblebeeForwardStatusSuccess,
  getBumblebeeReturnStatusSuccess
} from '../actions/MappingStatus'

import { callApi, callApiGetFile } from '../../util/api'

const fetchMappingStatusRequest = (payload) => callApi(
  '/api/v1.0/mapping-status',
  'GET',
  payload
)

function* fetchMappingStatus({ payload }) {
  const res = yield call(fetchMappingStatusRequest, payload)
  yield put(getMappingStatusSuccess(res))
}

export function* getMappingStatusWatcher() {
  yield takeEvery(GET_MAPPING_STATUS, fetchMappingStatus)
}

const fetchBumblebeeStatusRequest = (payload) => callApi(
  '/api/v1.0/mapping-status/getBumblebeeStatus',
  'GET',
  payload
)

function* fetchBumblebeeStatus({ payload }) {
  const res = yield call(fetchBumblebeeStatusRequest, payload)
  yield put(getBumblebeeStatusListSuccess(res))
}

export function* getBumblebeeStatusWatcher() {
  yield takeEvery(GET_BUMBLEBEE_STATUS_LIST, fetchBumblebeeStatus)
}

const editMappingStatusRequest = (payload) => callApi(
  '/api/v1.0/mapping-status/edit',
  'PUT',
  payload
)

function* editMappingStatus({ payload }) {
  const res = yield call(editMappingStatusRequest, payload)
  res && res.isSuccess ? yield put(editMappingStatusSuccess(res))
    : put(editMappingStatusFailed(res))
}

export function* editMappingStatusWatcher() {
  yield takeEvery(EDIT_MAPPING_STATUS, editMappingStatus)
}

const editCustomDataFieldsRequest = (payload) => callApi(
  '/api/v1.0/mapping-status/updateDataFields',
  'PUT',
  payload
)

function* editCustomDataFields({ payload }) {
  const res = yield call(editCustomDataFieldsRequest, payload)
  res && res.isSuccess ? yield put(editCustomDataFieldsSuccess(res))
    : put(editCustomDataFieldsFailed(res))
}

export function* editCustomDataFieldsWatcher() {
  yield takeEvery(EDIT_CUSTOM_DATA_FIELDS, editCustomDataFields)
}

const addMappingStatusRequest = (payload) => callApi(
  '/api/v1.0/mapping-status/add',
  'POST',
  payload
)

function* addMappingStatus({ payload }) {
  const res = yield call(addMappingStatusRequest, payload)
  res && res.isSuccess ? yield put(addMappingStatusSuccess(res))
    : put(addMappingStatusFailed(res))
}

export function* addMappingStatusWatcher() {
  yield takeEvery(ADD_MAPPING_STATUS, addMappingStatus)
}

const deleteMappingStatusRequest = (payload) => callApi(
  '/api/v1.0/mapping-status/delete',
  'POST',
  payload
)

function* deleteMappingStatus({ payload }) {
  const res = yield call(deleteMappingStatusRequest, payload)
  res && res.isSuccess ? yield put(deleteMappingStatusSuccess(res))
    : yield put(deleteMappingStatusFailed(res))
}

export function* deleteMappingStatusWatcher() {
  yield takeEvery(DELETE_MAPPING_STATUS, deleteMappingStatus)
}

const importMappingStatusRequest = (payload) => callApi(
  '/api/v1.0/file-import/importMappingStatus',
  'POST',
  payload,
  'upload'
)

function* importMappingStatus({ payload }) {
  const res = yield call(importMappingStatusRequest, payload)
  res && res.isSuccess ? yield put(importMappingStatusSuccess(res))
    : yield put(importMappingStatusFailed(res))
}

export function* importMappingStatusWatcher() {
  yield takeEvery(IMPORT_MAPPING_STATUS, importMappingStatus)
}

const fetchDownloadExampleRequest = (payload) => callApiGetFile(
  '/api/v1.0/mapping-status/downloadExample',
  'GET',
  payload
)

function* fetchDownloadExample({ payload }) {
  const fetchedCSV = yield call(fetchDownloadExampleRequest, payload)
  yield put(downloadExampleSuccess(fetchedCSV))
}

export function* downloadExampleWatcher() {
  yield takeEvery(DOWNLOAD_EXAMPLE, fetchDownloadExample)
}

const fetchExportMappingStatusRequest = (payload) => callApiGetFile(
  '/api/v1.0/mapping-status/export',
  'GET',
  payload,
)

function* fetchExportMappingStatus({ payload }) {
  const fetchedCSV = yield call(fetchExportMappingStatusRequest, payload)
  yield put(exportMappingStatusSuccess(fetchedCSV))
}

export function* exportMappingStatusWatcher() {
  yield takeEvery(EXPORT_MAPPING_STATUS, fetchExportMappingStatus)
}

function* fetchBumblebeeForwardStatus({payload}){
  const res = yield call(fetchBumblebeeStatusRequest, payload)
  yield put(getBumblebeeForwardStatusSuccess(res))
}

function* fetchBumblebeeReturnStatus({payload}){
  const res = yield call(fetchBumblebeeStatusRequest, payload)
  yield put(getBumblebeeReturnStatusSuccess(res))
}

export function* fetchBumblebeeForwardStatusWatcher(){
  yield takeEvery(GET_BUMBLEBEE_FORWARD_STATUS, fetchBumblebeeForwardStatus)
}

export function* fetchBumblebeeReturnStatusWatcher(){
  yield takeEvery(GET_BUMBLEBEE_RETURN_STATUS, fetchBumblebeeReturnStatus)
}


export default function* rootSaga() {
  yield all([
    fork(getMappingStatusWatcher),
    fork(editMappingStatusWatcher),
    fork(editCustomDataFieldsWatcher),
    fork(getBumblebeeStatusWatcher),
    fork(addMappingStatusWatcher),
    fork(deleteMappingStatusWatcher),
    fork(importMappingStatusWatcher),
    fork(downloadExampleWatcher),
    fork(exportMappingStatusWatcher),
    fork(fetchBumblebeeForwardStatusWatcher),
    fork(fetchBumblebeeReturnStatusWatcher)
  ])
}







