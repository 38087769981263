import {
  GET_LOCATION_MAPPING_TABLE,
  GET_LOCATION_MAPPING_TABLE_SUCCESS,
  GET_LOCATION_MAPPING,
  GET_LOCATION_MAPPING_SUCCESS,
  EDIT_LOCATION_MAPPING,
  EDIT_LOCATION_MAPPING_SUCCESS,
  EDIT_LOCATION_MAPPING_FAILED,
  ADD_LOCATION_MAPPING,
  ADD_LOCATION_MAPPING_SUCCESS,
  ADD_LOCATION_MAPPING_FAILED,
  DELETE_LOCATION_MAPPING,
  DELETE_LOCATION_MAPPING_SUCCESS,
  DELETE_LOCATION_MAPPING_FAILED,
  IMPORT_LOCATION_MAPPING,
  IMPORT_LOCATION_MAPPING_SUCCESS,
  IMPORT_LOCATION_MAPPING_FAILED,
  EXPORT_LOCATION_MAPPING,
  EXPORT_LOCATION_MAPPING_SUCCESS,
} from '../../constants/ActionTypes'

const INIT_STATE = {
  locationMappingList: {},
  locationTableList: {},
  isToReload: false,
  isSuccess: undefined,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_LOCATION_MAPPING_TABLE:
      return {
        ...state,
        loading: true
      }

    case GET_LOCATION_MAPPING_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        locationTableList: action.payload
      }

    case GET_LOCATION_MAPPING:
      return {
        ...state,
        loading: true
      }

    case GET_LOCATION_MAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        isToReload: false,
        locationMappingList: action.payload
      }

    case EDIT_LOCATION_MAPPING:
    case ADD_LOCATION_MAPPING:
    case DELETE_LOCATION_MAPPING:
    case IMPORT_LOCATION_MAPPING:
    case EXPORT_LOCATION_MAPPING:
      return {
        ...state,
        loading: true,
      }

    case EDIT_LOCATION_MAPPING_SUCCESS:
    case ADD_LOCATION_MAPPING_SUCCESS:
    case DELETE_LOCATION_MAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        isToReload: true,
        message: action.payload.message,
        isSuccess: action.payload.isSuccess
      }

    case EDIT_LOCATION_MAPPING_FAILED:
    case ADD_LOCATION_MAPPING_FAILED:
    case DELETE_LOCATION_MAPPING_FAILED:
      return {
        ...state,
        loading: false,
        isToReload: true,
        message: action.payload.message,
        isSuccess: action.payload.isSuccess
      }

    case IMPORT_LOCATION_MAPPING_SUCCESS:
    case IMPORT_LOCATION_MAPPING_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.isSuccess,
      }

    case EXPORT_LOCATION_MAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        exportFile: action.payload,
      }

    default:
      return state
  }
}
