import {
    EXPORT_PACKAGE,
    EXPORT_RETURN,
    UPDATE_PACKAGE,
    UPDATE_EVENT,
    UPDATE_RETURN_PACKAGE,
    UPDATE_RETURN_EVENT,
    EXPORT_ERROR_PACKAGE,
    EXPORT_ERROR_RETURN
} from '../../constants/ActionTypes'
import {
    exportPackageSuccess,
    exportReturnSuccess,
    updatePackageSuccess,
    updatePackageFailed,
    updateEventSuccess,
    updateEventFailed,
    updateReturnPackageSuccess,
    updateReturnPackageFailed,
    updateReturnEventSuccess,
    updateReturnEventFailed,
    exportErrorPackageSuccess,
    exportErrorReturnSuccess
} from '../actions/ExportUpdateData'
import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import { callApi, callApiGetFile } from '../../util/api'

//////////////
const fetchExportPackageRequest = (payload) => callApiGetFile(
    '/api/1.0/update-extract-data/extract-package',
    'GET',
    payload,
)

function* fetchExportPackage({ payload }) {
    const fetchedCSV = yield call(fetchExportPackageRequest, payload)
    yield put(exportPackageSuccess(fetchedCSV))
}

export function* exportPackageWatcher() {
    yield takeEvery(EXPORT_PACKAGE, fetchExportPackage)
}

////////////////////////////////////////////////////////////////
const fetchExportReturnRequest = (payload) => callApiGetFile(
    '/api/1.0/update-extract-data/extract-return-package',
    'GET',
    payload,
)


function* fetchExportReturn({ payload }) {
    const fetchedCSV = yield call(fetchExportReturnRequest, payload)
    yield put(exportReturnSuccess(fetchedCSV))
}

export function* exportReturnWatcher() {
    yield takeEvery(EXPORT_RETURN, fetchExportReturn)
}

//////////////
const fetchExportErrorPackageRequest = (payload) => callApiGetFile(
    '/api/1.0/update-extract-data/extract-error-package',
    'GET',
    payload,
)

function* fetchExportErrorPackage({ payload }) {
    console.log(payload);
    const fetchedCSV = yield call(fetchExportErrorPackageRequest, payload)
    yield put(exportErrorPackageSuccess(fetchedCSV))
}

export function* exportErrorPackageWatcher() {
    yield takeEvery(EXPORT_ERROR_PACKAGE, fetchExportErrorPackage)
}

////////////////////////////////////////////////////////////////
const fetchExportErrorReturnRequest = (payload) => callApiGetFile(
    '/api/1.0/update-extract-data/extract-error-return-package',
    'GET',
    payload,
)


function* fetchExportErrorReturn({ payload }) {
    const fetchedCSV = yield call(fetchExportErrorReturnRequest, payload)
    yield put(exportErrorReturnSuccess(fetchedCSV))
}

export function* exportErrorReturnWatcher() {
    yield takeEvery(EXPORT_ERROR_RETURN, fetchExportErrorReturn)
}
/////////////////////////////////////
const fetchUpdatePackageRequest = (payload) => callApi(
    '/api/1.0/update-extract-data/update-package-status',
    'POST',
    payload,
)

function* fetchUpdatePackage({payload}){
    const response = yield call(fetchUpdatePackageRequest, payload)
    if (response.isSuccess){
        yield put(updatePackageSuccess(response))
    }else{
        yield put(updatePackageFailed(response))      
    }
}

export function* updatePackageWatcher() {
    yield takeEvery(UPDATE_PACKAGE, fetchUpdatePackage)
}

/////////////////////////////////////
const fetchUpdateReturnPackageRequest = (payload) => callApi(
    '/api/1.0/update-extract-data/update-return-package-status',
    'POST',
    payload,
)

function* fetchUpdateReturnPackage({payload}){
    const response = yield call(fetchUpdateReturnPackageRequest, payload)
    if (response.isSuccess){
        yield put(updateReturnPackageSuccess(response))
    }else{
        yield put(updateReturnPackageFailed(response))      
    }
}

export function* updateReturnPackageWatcher() {
    yield takeEvery(UPDATE_RETURN_PACKAGE, fetchUpdateReturnPackage)
}

////////////////////////////////////
const fetchUpdateEventRequest = (payload) => callApi(
    '/api/1.0/update-extract-data/trigger-sync-event',
    'POST',
    payload,
)

function* fetchUpdateEvent({payload}){
    const response = yield call(fetchUpdateEventRequest, payload)
    if (response.isSuccess){
        yield put(updateEventSuccess(response))
    }else{
        yield put(updateEventFailed(response))
    }
}

export function* updateEventWatcher() {
    yield takeEvery(UPDATE_EVENT, fetchUpdateEvent)
}
////////////////////////////////////
const fetchUpdateReturnEventRequest = (payload) => callApi(
    '/api/1.0/update-extract-data/trigger-return-sync-event',
    'POST',
    payload,
)

function* fetchUpdateReturnEvent({payload}){
    const response = yield call(fetchUpdateReturnEventRequest, payload)
    if (response.isSuccess){
        yield put(updateReturnEventSuccess(response))
    }else{
        yield put(updateReturnEventFailed(response))
    }
}

export function* updateReturnEventWatcher() {
    yield takeEvery(UPDATE_RETURN_EVENT, fetchUpdateReturnEvent)
}

export default function* rootSaga() {
    yield all([
        fork(exportPackageWatcher),
        fork(exportReturnWatcher),
        fork(updatePackageWatcher),
        fork(updateEventWatcher),
        fork(updateReturnPackageWatcher),
        fork(updateReturnEventWatcher),
        fork(exportErrorPackageWatcher),
        fork(exportErrorReturnWatcher)
    ])
}
