import {
  EDIT_MAPPING_STATUS,
  EDIT_MAPPING_STATUS_SUCCESS,
  EDIT_MAPPING_STATUS_FAILED,
  EDIT_CUSTOM_DATA_FIELDS,
  EDIT_CUSTOM_DATA_FIELDS_SUCCESS,
  EDIT_CUSTOM_DATA_FIELDS_FAILED,
  GET_MAPPING_STATUS,
  GET_MAPPING_STATUS_SUCCESS,
  GET_BUMBLEBEE_STATUS_LIST,
  GET_BUMBLEBEE_STATUS_LIST_SUCCESS,
  ADD_MAPPING_STATUS,
  ADD_MAPPING_STATUS_SUCCESS,
  ADD_MAPPING_STATUS_FAILED,
  DELETE_MAPPING_STATUS,
  DELETE_MAPPING_STATUS_SUCCESS,
  DELETE_MAPPING_STATUS_FAILED,
  IMPORT_MAPPING_STATUS,
  IMPORT_MAPPING_STATUS_SUCCESS,
  IMPORT_MAPPING_STATUS_FAILED, 
  RESET,
  DOWNLOAD_EXAMPLE,
  DOWNLOAD_EXAMPLE_SUCCESS,
  EXPORT_MAPPING_STATUS,
  EXPORT_MAPPING_STATUS_SUCCESS,
  GET_BUMBLEBEE_FORWARD_STATUS,
  GET_BUMBLEBEE_RETURN_STATUS,
  GET_BUMBLEBEE_FORWARD_STATUS_SUCCESS,
  GET_BUMBLEBEE_RETURN_STATUS_SUCCESS
} from '../../constants/ActionTypes'

export const getMappingStatus = (payload) => (
  {
    type: GET_MAPPING_STATUS,
    payload
  }
)

export const getMappingStatusSuccess = (mappingStatusList) => (
  {
    type: GET_MAPPING_STATUS_SUCCESS,
    payload: mappingStatusList
  }
)
export const getBumblebeeStatusList = (payload) => (
  {
    type: GET_BUMBLEBEE_STATUS_LIST,
    payload
  }
)

export const getBumblebeeStatusListSuccess = (bumbleStatusList) => (
  {
    type: GET_BUMBLEBEE_STATUS_LIST_SUCCESS,
    payload: bumbleStatusList
  }
)

export const editMappingStatus = (payload) => (
  {
    type: EDIT_MAPPING_STATUS,
    payload
  }
)

export const editMappingStatusSuccess = (message) => (
  {
    type: EDIT_MAPPING_STATUS_SUCCESS,
    payload: message
  }
)

export const editMappingStatusFailed = (message) => {
  return {
    type: EDIT_MAPPING_STATUS_FAILED,
    payload: message
  }
}

export const editCustomDataFields = (payload) => (
  {
    type: EDIT_CUSTOM_DATA_FIELDS,
    payload
  }
)

export const editCustomDataFieldsSuccess = (message) => (
  {
    type: EDIT_CUSTOM_DATA_FIELDS_SUCCESS,
    payload: message
  }
)

export const editCustomDataFieldsFailed = (message) => {
  return {
    type: EDIT_CUSTOM_DATA_FIELDS_FAILED,
    payload: message
  }
}

export const addMappingStatus = (payload) => (
  {
    type: ADD_MAPPING_STATUS,
    payload
  }
)

export const addMappingStatusSuccess = payload => (
  {
    type: ADD_MAPPING_STATUS_SUCCESS,
    payload
  }
)

export const addMappingStatusFailed = payload => {
  return {
    type: ADD_MAPPING_STATUS_FAILED,
    payload
  }
}

export const deleteMappingStatus = (payload) => (
  {
    type: DELETE_MAPPING_STATUS,
    payload
  }
)

export const deleteMappingStatusSuccess = payload => (
  {
    type: DELETE_MAPPING_STATUS_SUCCESS,
    payload
  }
)

export const deleteMappingStatusFailed = payload => (
  {
    type: DELETE_MAPPING_STATUS_FAILED,
    payload
  }
)

export const importMappingStatus = (payload) => (
  {
    type: IMPORT_MAPPING_STATUS,
    payload
  }
)

export const importMappingStatusSuccess = (payload) => (
  {
    type: IMPORT_MAPPING_STATUS_SUCCESS,
    payload
  }
)

export const importMappingStatusFailed = (payload) => (
  {
    type: IMPORT_MAPPING_STATUS_FAILED,
    payload
  }
)

export const reset = () => (
  {
    type: RESET
  }
)

export const downloadExample = (payload) => (
  {
      type: DOWNLOAD_EXAMPLE,
      payload
  }
)

export const downloadExampleSuccess = (payload) => (
  {
      type: DOWNLOAD_EXAMPLE_SUCCESS,
      payload
  }
)

export const exportMappingStatus = (payload) => (
  {
      type: EXPORT_MAPPING_STATUS,
      payload
  }
)

export const exportMappingStatusSuccess = (payload) => (
  {
      type: EXPORT_MAPPING_STATUS_SUCCESS,
      payload
  }
)

export const getBumblebeeForwardStatus = (payload) => (
  {
    type: GET_BUMBLEBEE_FORWARD_STATUS,
    payload
  }
)

export const getBumblebeeForwardStatusSuccess = (payload) => (
  {
    type: GET_BUMBLEBEE_FORWARD_STATUS_SUCCESS,
    payload
  }
)

export const getBumblebeeReturnStatus = (payload) => (
  {
    type: GET_BUMBLEBEE_RETURN_STATUS,
    payload
  }
)


export const getBumblebeeReturnStatusSuccess = (payload) => (
  {
    type: GET_BUMBLEBEE_RETURN_STATUS_SUCCESS,
    payload
  }
)