import {
  GET_HUB_CODE,
  GET_HUB_CODE_SUCCESS,
  GET_HUB_CODE_DETAIL,
  GET_HUB_CODE_DETAIL_SUCCESS,
  DELETE_HUB_CODE,
  DELETE_HUB_CODE_SUCCESS,
  EDIT_HUB_CODE,
  EDIT_HUB_CODE_SUCCESS,
  IMPORT_HUB_CODE,
  IMPORT_HUB_CODE_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER
} from '../../constants/ActionTypes'

const INIT_STATE = {
  hubcodeList: {},
  selectedHubcode: {},
  deletedHubcode: undefined,
  editedHubcode: undefined,
  loading: false,
  showMessage: false,
  alertMessage: ''
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

  case GET_HUB_CODE:
    return {
      ...state,
      loading: true
    }

  case GET_HUB_CODE_SUCCESS:
    return {
      ...state,
      loading: false,
      hubcodeList: action.payload
    }

  case GET_HUB_CODE_DETAIL:
    return {
      ...state,
      loading: true
    }

  case GET_HUB_CODE_DETAIL_SUCCESS:
    return {
      ...state,
      loading: false,
      selectedHubcode: action.payload
    }

  case DELETE_HUB_CODE:
    return {
      ...state,
      loading: true
    }

  case DELETE_HUB_CODE_SUCCESS:
    return {
      ...state,
      loading: false,
      deletedHubcode: action.payload
    }

  case EDIT_HUB_CODE:
    return {
      ...state,
      loading: true
    }

  case EDIT_HUB_CODE_SUCCESS:
    return {
      ...state,
      loading: false,
      editedHubcode: action.payload
    }

    case IMPORT_HUB_CODE:
      return {
        ...state,
        loading: true
      }

    case IMPORT_HUB_CODE_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loading: false
      }
    }

  case HIDE_MESSAGE: {
    return {
      ...state,
      alertMessage: '',
      showMessage: false,
      loading: false
    }
  }

  case ON_SHOW_LOADER: {
    return {
      ...state,
      loading: true
    }
  }

  case ON_HIDE_LOADER: {
    return {
      ...state,
      loading: false
    }
  }

  default:
    return state
  }
}
