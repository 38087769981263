import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {GET_UNMAPPED_STATUS} from '../../constants/ActionTypes'
import {getUnmappedStatusesSuccess} from '../actions/UnmappedStatus'

import { callApi } from '../../util/api'

const fetchUnmappedStatusRequest = (payload) => callApi(
  '/api/v1.0/mapping-status/getUnmappedStatus',
  'GET',
  payload
)


function* fetchUnmappedStatus({ payload }) {
  const fetchedUnmappedStatus = yield call(fetchUnmappedStatusRequest, payload)
  yield put(getUnmappedStatusesSuccess(fetchedUnmappedStatus))
}

export function* fetchUnmappedStatusWatcher() {
  yield takeEvery(GET_UNMAPPED_STATUS, fetchUnmappedStatus)
}

export default function* rootSaga() {
  yield all([
      fork(fetchUnmappedStatusWatcher)
  ])
}
