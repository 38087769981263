import {
  GET_LIVE_TRACKING_RETURN_PACKAGES,
  GET_LIVE_TRACKING_RETURN_PACKAGES_SUCCESS,
  GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL,
  GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL_SUCCESS,
  GET_LIVE_TRACKING_RETURN_PACKAGES_LOG,
  GET_LIVE_TRACKING_RETURN_PACKAGES_LOG_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  packagesList: {},
  packageLogs: undefined,
  loading: false
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

  case GET_LIVE_TRACKING_RETURN_PACKAGES:
    return {
      ...state,
      loading: true
    }

  case GET_LIVE_TRACKING_RETURN_PACKAGES_SUCCESS:
    return {
      ...state,
      loading: false,
      packagesList: action.payload
    }

  case GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL:
    return {
      ...state,
      loading: true
    }

  case GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL_SUCCESS:
    return {
      ...state,
      loading: false,
      packageLogs: action.payload
    }

    case GET_LIVE_TRACKING_RETURN_PACKAGES_LOG:
      return {
        ...state,
        loading:true
      }

    case GET_LIVE_TRACKING_RETURN_PACKAGES_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        packagesList: action.payload
      }


    default:
    return state
  }
}
