import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import { GET_CRONJOB_STATUS, TRIGGER_CRONJOB} from '../../constants/ActionTypes'
import { getCronjobStatusSuccess, triggerCronjobSuccess, triggerCronjobFail} from '../actions/Cronjob'
import {callApi} from "../../util/api";

const fetchCronjobStatusesRequest = (payload) => callApi(
  '/api/v1.0/cronjob',
  'GET',
  payload
)

const triggerCronjobRequest = (payload) => callApi(
  '/api/v1.0/cron-job/triggerCronjob',
  'POST',
  payload
)

function* fetchCronjobStatuses({ payload }) {
  const fetchedCronjobStatuses = yield call(fetchCronjobStatusesRequest, payload)
  yield put(getCronjobStatusSuccess(fetchedCronjobStatuses))
}

function* triggerCronjob({ payload }) {
  const triggerCronjobResponse = yield call(triggerCronjobRequest, payload)
  if (triggerCronjobResponse.success) {
    yield put(triggerCronjobSuccess({'message' : 'Cronjob is triggered', 'log' : triggerCronjobResponse}))
  } else {
    yield put(triggerCronjobFail(triggerCronjobResponse))
  }
}

export function* fetchCronjobStatusesWatcher() {
  yield takeEvery(GET_CRONJOB_STATUS, fetchCronjobStatuses)
}

export function* triggerCronjobWatcher() {
  yield takeEvery(TRIGGER_CRONJOB, triggerCronjob)
}

export default function* rootSaga() {
  yield all([
    fork(fetchCronjobStatusesWatcher),
    fork(triggerCronjobWatcher),
  ])
}
