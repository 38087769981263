import {
  GET_DASHBOARD_TOP_3PL_PACKAGES,
  GET_DASHBOARD_TOP_3PL_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES,
  GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS
} from '../../../constants/ActionTypes'

const INIT_STATE = {
  top3PLPackages: {},
  totalNumberPackages: 0,
  loadingPackages: false,
  headerTotalNumberPackages: 0,
  loadingTotalPackages: false,
  top3PLErrorPackages: {},
  totalNumberErrorPackages: 0,
  loadingErrorPackages: false,
  headerTotalNumberErrorPackages: 0,
  loadingTotalErrorPackages: false,
  top3PLLiveTrackingErrorPackages: {},
  totalNumberLiveTrackingErrorPackages: 0,
  loadingLiveTrackingErrorPackages: false,
  headerTotalNumberLiveTrackingErrorPackages: 0,
  loadingTotalLiveTrackingErrorPackages: false,
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Packages
    case GET_DASHBOARD_TOP_3PL_PACKAGES:
      return {
        ...state,
        loadingPackages: true
      }

    case GET_DASHBOARD_TOP_3PL_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingPackages: false,
        top3PLPackages: action.payload.top3PLPackages,
        totalNumberPackages: action.payload.totalNumberPackages
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES:
      return {
        ...state,
        loadingTotalPackages: true
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingTotalPackages: false,
        headerTotalNumberPackages: action.payload
      }

    // Error Packages
    case GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES:
      return {
        ...state,
        loadingErrorPackages: true
      }

    case GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingErrorPackages: false,
        top3PLErrorPackages: action.payload.top3PLErrorPackages,
        totalNumberErrorPackages: action.payload.totalNumberErrorPackages
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES:
      return {
        ...state,
        loadingTotalErrorPackages: true
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingTotalErrorPackages: false,
        headerTotalNumberErrorPackages: action.payload
      }

    // Live Tracking Error Packages
    case GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES:
      return {
        ...state,
        loadingLiveTrackingErrorPackages: true
      }

    case GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingLiveTrackingErrorPackages: false,
        top3PLLiveTrackingErrorPackages: action.payload.top3PLLiveTrackingErrorPackages,
        totalNumberLiveTrackingErrorPackages: action.payload.totalNumberLiveTrackingErrorPackages
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES:
      return {
        ...state,
        loadingTotalLiveTrackingErrorPackages: true
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingTotalLiveTrackingErrorPackages: false,
        headerTotalNumberLiveTrackingErrorPackages: action.payload
      }

    default:
      return state
  }
}
