
import { takeEvery, call, all, fork, put, take } from 'redux-saga/effects'
import {
  GET_PROVIDERS, GET_PROVIDERS_BY_COUNTRY, GET_PROVIDERS_PAGINATION,
  GET_PROVIDER_SELLERS_BY_COUNTRY, UPDATE_PROVIDER_BY_ID
} from '../../constants/ActionTypes'
import {
  getProvidersSuccess, getProvidersByCountrySuccess,
  getProviderSellersByCountrySuccess,
  updateProviderByIdSuccess,
  updateProviderByIdFail,
  getProvidersPaginationSuccess
} from '../actions/Provider'
import { callApi, callApiGetFile } from '../../util/api'

const fetchProvidersRequest = (payload) => callApi(
  '/api/v1.0/provider/getList',
  'GET',
  payload
)

function* fetchProviders({ payload }) {
  const fetchedProviders = yield call(fetchProvidersRequest, payload)
  yield put(getProvidersSuccess(fetchedProviders))
}

export function* fetchProvidersWatcher() {
  yield takeEvery(GET_PROVIDERS, fetchProviders)
}

const fetchProvidersByCountryRequest = (payload) => callApi(
  '/api/v1.0/provider/getListByCountry',
  'GET',
  payload
)

function* fetchProvidersByCountry({ payload }) {
  const fetchedProviders = yield call(fetchProvidersByCountryRequest, payload)
  yield put(getProvidersByCountrySuccess(fetchedProviders))
}

export function* fetchProvidersByCountryWatcher() {
  yield takeEvery(GET_PROVIDERS_BY_COUNTRY, fetchProvidersByCountry)
}

const fetchProviderSellersByCountryRequest = (payload) => callApi(
  '/api/v1.0/seller-account/getShipmentProviderSellerListByCountry',
  'GET',
  payload
)

function* fetchProviderSellersByCountry({ payload }) {
  const fetchedProviders = yield call(fetchProviderSellersByCountryRequest, payload)
  yield put(getProviderSellersByCountrySuccess(fetchedProviders))
}

export function* fetchProviderSellersByCountryWatcher() {
  yield takeEvery(GET_PROVIDER_SELLERS_BY_COUNTRY, fetchProviderSellersByCountry)
}

const fetchProvidersPaginationRequest = (payload) => callApi(
  '/api/v1.0/provider/getListShipmentProviderPagination',
  'GET',
  payload,
)

function* fetchProvidersPagination({ payload }) {
  const providers = yield call(fetchProvidersPaginationRequest, payload)
  yield put(getProvidersPaginationSuccess(providers))

}

export function* fetchProvidersPaginationWatcher() {
  yield takeEvery(GET_PROVIDERS_PAGINATION, fetchProvidersPagination)
}

const postUpdateShipmentProviderById = async (payload) => {
  const { data, errorCallback, successCallback } = payload
  const response = await callApi(
    '/api/v1.0/provider/update',
    'POST',
    data,
    'upload'
  )
  return {
    response, errorCallback, successCallback
  }
}


export function* postUpdateProviderById({ payload }) {
  const updateProvider = yield call(postUpdateShipmentProviderById, payload)
  const { response, successCallback, errorCallback } = updateProvider
  if (response && response.success) {
    put(updateProviderByIdSuccess(response))
    successCallback()
  } else {
    yield put(updateProviderByIdFail(response))
    errorCallback(response.message)
  }
}

export function* postUpdateProviderWatcher() {
  yield takeEvery(UPDATE_PROVIDER_BY_ID, postUpdateProviderById)
}


export default function* rootSaga() {
  yield all([
    fork(fetchProvidersWatcher),
    fork(fetchProvidersByCountryWatcher),
    fork(fetchProviderSellersByCountryWatcher),
    fork(postUpdateProviderWatcher),
    fork(fetchProvidersPaginationWatcher)
  ])
}
