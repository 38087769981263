import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Avatar, Popover } from 'antd'
import { userSignOut } from 'appRedux/actions/Auth'
import { Link } from 'react-router-dom'
import Placeholder from '../../assets/images/placeholder.jpg'
import { ACCOUNT_TYPE } from '../../appRedux/sagas/Auth'

class UserProfile extends Component {

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        {localStorage.getItem('account_type') === ACCOUNT_TYPE.USERNAME && <li><Link style={{ color: '#545454' }} to="/reset-password">Reset Password</Link></li>}
        <li onClick={() => this.props.userSignOut()}>Logout</li>
      </ul>
    )

    return (

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={Placeholder}
            className="gx-size-40 gx-pointer gx-mr-3" alt="" />
          <span
            className="gx-avatar-name">
            My Account
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </Popover>
      </div>

    )

  }
}

export default connect(null, { userSignOut })(UserProfile)
