import {
    EXPORT_PACKAGE,
    EXPORT_PACKAGE_SUCCESS,
    EXPORT_RETURN,
    EXPORT_RETURN_SUCCESS,
    UPDATE_PACKAGE,
    UPDATE_PACKAGE_SUCCESS,
    UPDATE_PACKAGE_FAILED,
    UPDATE_EVENT,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAILED,
    UPDATE_RETURN_PACKAGE,
    UPDATE_RETURN_PACKAGE_SUCCESS,
    UPDATE_RETURN_PACKAGE_FAILED,
    UPDATE_RETURN_EVENT,
    UPDATE_RETURN_EVENT_SUCCESS,
    UPDATE_RETURN_EVENT_FAILED,
    EXPORT_ERROR_PACKAGE,
    EXPORT_ERROR_PACKAGE_SUCCESS,
    EXPORT_ERROR_RETURN,
    EXPORT_ERROR_RETURN_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
    exportFile: undefined,
    isUpdateAction: false,
    updateSuccess: false,
    isExportLoading: false,
    isUpdateLoading: false,
    isEventLoading: false,
    isToReload: false,
    responseMessage:undefined
}
export default (state = INIT_STATE, action)=>{
    switch(action.type){
        case EXPORT_PACKAGE:
        case EXPORT_RETURN:
        case EXPORT_ERROR_PACKAGE:
        case EXPORT_ERROR_RETURN:{
            return {
                ...state,
                isExportLoading: true,
                isUpdateAction:false,
                isToReload: false,
            }
        }
        case UPDATE_PACKAGE:
        case UPDATE_RETURN_PACKAGE:{
            return {
                ...state,
                isUpdateLoading: true,
                isUpdateAction:false,
                isToReload: false,
            }
        }
        case UPDATE_EVENT:
        case UPDATE_RETURN_EVENT:{
            return {
                ...state,
                isEventLoading: true,
                isUpdateAction:false,
                isToReload: false,
            }
        }

        case EXPORT_PACKAGE_SUCCESS:
        case EXPORT_RETURN_SUCCESS:
        case EXPORT_ERROR_PACKAGE_SUCCESS:
        case EXPORT_ERROR_RETURN_SUCCESS:{
            return {
                ...state,
                exportFile: action.payload,
                isUpdateAction: false,
                isExportLoading: false,
                isToReload: true
            }
        }

        case UPDATE_PACKAGE_SUCCESS:
        case UPDATE_RETURN_PACKAGE_SUCCESS:{
            return {
                ...state,
                isUpdateAction: true,
                updateSuccess: true,
                isUpdateLoading: false,
                responseMessage: action.payload.message,
                isToReload: true
            }
        }
        case UPDATE_EVENT_SUCCESS:
        case UPDATE_RETURN_EVENT_SUCCESS:{
            return {
                ...state,
                isUpdateAction: true,
                updateSuccess: true,
                isEventLoading: false,
                responseMessage: action.payload.message,
                isToReload: true
            }
        }
        case UPDATE_PACKAGE_FAILED:
        case UPDATE_RETURN_PACKAGE_FAILED:{
            return {
                ...state,
                isUpdateAction: true,
                updateSuccess: false,
                isUpdateLoading: false,
                responseMessage: action.payload.message,
                isToReload: true
            }
        }
        case UPDATE_EVENT_FAILED:
        case UPDATE_RETURN_EVENT_FAILED:{
            return {
                ...state,
                isUpdateAction: true,
                updateSuccess: false,
                isEventLoading: false,
                responseMessage: action.payload.message,
                isToReload: true
            }
        }

        default: return state
    }
}