import {
  GET_DASHBOARD_TOP_USERS_LOGIN,
  GET_DASHBOARD_TOP_USERS_LOGIN_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN_SUCCESS
} from '../../../constants/ActionTypes'

const INIT_STATE = {
  topUserLoginList: {},
  headerTotalNumberUserLogin: 0,
  totalNumberUserLogin: 0,
  loadingUserLogin: false,
  loadingTotalUserLogin: false
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // User Login
    case GET_DASHBOARD_TOP_USERS_LOGIN:
      return {
        ...state,
        loadingUserLogin: true
      }

    case GET_DASHBOARD_TOP_USERS_LOGIN_SUCCESS:
      return {
        ...state,
        loadingUserLogin: false,
        topUserLoginList: action.payload.topUserLoginList,
        totalNumberUserLogin: action.payload.totalNumberUserLogin
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN:
      return {
        ...state,
        loadingTotalUserLogin: true
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN_SUCCESS:
      return {
        ...state,
        loadingTotalUserLogin: false,
        headerTotalNumberUserLogin: action.payload
      }

    default:
      return state
  }
}
