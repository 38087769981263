import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {GET_LIVE_TRACKING_RETURN_PACKAGES, GET_LIVE_TRACKING_RETURN_PACKAGES_LOG, GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL} from '../../constants/ActionTypes'
import { getLiveTrackingReturnPackagesSuccess, getLiveTrackingReturnPackageDetailSuccess, getLiveTrackingReturnPackagesLogSuccess } from '../actions/LiveTrackingReturnPackages'

import { callApi } from '../../util/api'

const fetchLiveTrackingPackagesRequest = (payload) => callApi(
    '/api/v1.0/return-package/indexlivetrackingReturn',
    'GET',
  payload
)

const fetchLiveTrackingReturnPackagesLogRequest = (payload) => callApi(
    '/api/v1.0/return-package/getlivetrackinglog',
    'GET',
    payload
)

const fetchLiveTrackingReturnPackageDetailRequest = (payload) => callApi(
  '/api/v1.0/return-package/getlivetrackingdetail',
  'GET',
  payload
)

function* fetchLiveTrackingReturnPackages({ payload }) {
  const fetchedLiveTrackingPackages = yield call(fetchLiveTrackingPackagesRequest, payload)
  yield put(getLiveTrackingReturnPackagesSuccess(fetchedLiveTrackingPackages))
}

function* fetchLiveTrackingReturnPackagesLog({ payload }) {
    const fetchedLiveTrackingReturnPackagesLog = yield call(fetchLiveTrackingReturnPackagesLogRequest, payload)
    yield put(getLiveTrackingReturnPackagesLogSuccess(fetchedLiveTrackingReturnPackagesLog))
}

function* fetchLiveTrackingReturnPackageDetail({ payload }) {
  const fetchedLiveTrackingReturnPackageDetail = yield call(fetchLiveTrackingReturnPackageDetailRequest, payload)
  yield put(getLiveTrackingReturnPackageDetailSuccess(fetchedLiveTrackingReturnPackageDetail))
}

export function* fetchLiveTrackingReturnPackagesWatcher() {
  yield takeEvery(GET_LIVE_TRACKING_RETURN_PACKAGES, fetchLiveTrackingReturnPackages)
}

export function* fetchLiveTrackingReturnPackagesLogWatcher() {
    yield takeEvery(GET_LIVE_TRACKING_RETURN_PACKAGES_LOG, fetchLiveTrackingReturnPackagesLog)
}

export function* fetchLiveTrackingReturnPackageDetailWatcher() {
  yield takeEvery(GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL, fetchLiveTrackingReturnPackageDetail)
}

export default function* rootSaga() {
  yield all([
      fork(fetchLiveTrackingReturnPackagesWatcher),
      fork(fetchLiveTrackingReturnPackageDetailWatcher),
      fork(fetchLiveTrackingReturnPackagesLogWatcher)
  ])
}
