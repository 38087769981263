// Customizer const
export const NAV_STYLE_MINI = 'NAV_STYLE_MINI'

export const NAV_STYLE = 'NAV_STYLE'

export const NAV_STYLE_FIXED = 'NAV_STYLE_FIXED'
export const NAV_STYLE_MINI_SIDEBAR = 'NAV_STYLE_MINI_SIDEBAR'
export const NAV_STYLE_DRAWER = 'NAV_STYLE_DRAWER'
export const NAV_STYLE_NO_HEADER_MINI_SIDEBAR = 'NAV_STYLE_NO_HEADER_MINI_SIDEBAR'
export const NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR = 'NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR'

export const TAB_SIZE = 992
