import {
  GET_FAREYE_TRACKING_LOG,
  GET_FAREYE_TRACKING_LOG_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  logList: {},
  loading: false
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case GET_FAREYE_TRACKING_LOG:
    return {
      ...state,
      loading: true
    }

  case GET_FAREYE_TRACKING_LOG_SUCCESS:
    return {
      ...state,
      loading: false,
      logList: action.payload
    }

  default:
    return state
  }
}
