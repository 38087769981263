import { history } from '../appRedux/store/index'

export const callApi = async (url, method, params, type) => {
  const fetchRequest = createFetchRequest(url, method, params, type)

  const request = await fetchRequest
  const response = await request.json()
  switch (request.status) {
    case 401:
      history.push('/ErrorPages/401/index')
      break
    case 403:
      history.push('/ErrorPages/403/index')
      break
    default:
      break
  }
  return response
}

export const callApiGetFile = async (url, method, params, type) => {
  const fetchRequest = createFetchRequest(url, method, params, type)

  const request = await fetchRequest
  const response = await request
  return response.blob()
}

const createFetchRequest = (url, method, params, type) => {
  const body = ['POST', 'PUT'].indexOf(method) >= 0 && params
    ? (type === 'upload' ? getFormData(params) : JSON.stringify(params))
    : undefined

  const queryParams = ['GET'].indexOf(method) >= 0 && params
    ? `?${getEncodedUrlParams(params)}`
    : ''

  const fetchRequest = fetch(`${getApiUrl(url)}${queryParams}`, {
    method,
    headers: {
      ...{
        'authorization': localStorage.getItem('api_key'),

      }, ...(type === 'upload') ? {} : { 'Content-Type': 'application/json' }
    },
    body
  })


  return fetchRequest
}

const getFormData = (params) => {
  const formData = new FormData()
  Object.keys(params).forEach(key => {
    formData.append(key, params[key])
  })
  return formData
}

const getApiUrl = (url) => {
  if (/^http[s]*:\/\/.+/.test(url)) {
    return url
  }

  // eslint-disable-next-line
  return `${url}`
}

const getEncodedUrlParams = params => Object.keys(params)
  .map(key => (params[key] ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}` : ''))
  .join('&')
