import {
  GET_LIVE_TRACKING_RETURN_PACKAGES,
  GET_LIVE_TRACKING_RETURN_PACKAGES_SUCCESS,
  GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL,
  GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL_SUCCESS,
  GET_LIVE_TRACKING_RETURN_PACKAGES_LOG,
  GET_LIVE_TRACKING_RETURN_PACKAGES_LOG_SUCCESS
} from '../../constants/ActionTypes'

export const getLiveTrackingReturnPackages = (payload) => (
  {
    type: GET_LIVE_TRACKING_RETURN_PACKAGES,
    payload
  }
)

export const getLiveTrackingReturnPackagesSuccess = (packagesList) => (
  {
    type: GET_LIVE_TRACKING_RETURN_PACKAGES_SUCCESS,
    payload: packagesList
  }
)

export const getLiveTrackingReturnPackageDetail = (payload) => (
  {
    type: GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL,
    payload
  }
)

export const getLiveTrackingReturnPackageDetailSuccess = (packageLogs) => (
  {
    type: GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL_SUCCESS,
    payload: packageLogs
  }
)

export const getLiveTrackingReturnPackagesLog = (payload) => (
    {
        type: GET_LIVE_TRACKING_RETURN_PACKAGES_LOG,
        payload
    }
)

export const getLiveTrackingReturnPackagesLogSuccess = (packagesList) => (
    {
        type: GET_LIVE_TRACKING_RETURN_PACKAGES_LOG_SUCCESS,
        payload: packagesList
    }
)
