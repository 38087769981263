import {
    EXPORT_PACKAGE,
    EXPORT_PACKAGE_SUCCESS,
    EXPORT_RETURN,
    EXPORT_RETURN_SUCCESS,
    UPDATE_PACKAGE,
    UPDATE_PACKAGE_SUCCESS,
    UPDATE_PACKAGE_FAILED,
    UPDATE_EVENT,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAILED,
    UPDATE_RETURN_PACKAGE,
    UPDATE_RETURN_PACKAGE_SUCCESS,
    UPDATE_RETURN_PACKAGE_FAILED,
    UPDATE_RETURN_EVENT,
    UPDATE_RETURN_EVENT_SUCCESS,
    UPDATE_RETURN_EVENT_FAILED,
    EXPORT_ERROR_PACKAGE,
    EXPORT_ERROR_PACKAGE_SUCCESS,
    EXPORT_ERROR_RETURN,
    EXPORT_ERROR_RETURN_SUCCESS
} from '../../constants/ActionTypes'

export const exportPackage = (payload) => (
    {
        type: EXPORT_PACKAGE,
        payload
    }
)

export const exportPackageSuccess = (payload) => (
    {
        type: EXPORT_PACKAGE_SUCCESS,
        payload
    }
)
export const exportReturn = (payload) => (
    {
        type: EXPORT_RETURN,
        payload
    }
)

export const exportReturnSuccess = (payload) => (
    {
        type: EXPORT_RETURN_SUCCESS,
        payload
    }
)

export const updatePackage = (payload) => (
    {
        type:UPDATE_PACKAGE,
        payload
    }
)
export const updatePackageSuccess = (payload) => (
    {
        type:UPDATE_PACKAGE_SUCCESS,
        payload
    }
)
export const updatePackageFailed = (payload) => (
    {
        type:UPDATE_PACKAGE_FAILED,
        payload
    }
)
export const updateEvent = (payload) => (
    {
        type:UPDATE_EVENT,
        payload
    }
)
export const updateEventSuccess = (payload) => (
    {
        type: UPDATE_EVENT_SUCCESS,
        payload
    }
)
export const updateEventFailed = (payload) => (
    {
        type:UPDATE_EVENT_FAILED,
        payload
    }
)
export const updateReturnPackage = (payload) => (
    {
        type:UPDATE_RETURN_PACKAGE,
        payload
    }
)
export const updateReturnPackageSuccess = (payload) => (
    {
        type: UPDATE_RETURN_PACKAGE_SUCCESS,
        payload
    }
)
export const updateReturnPackageFailed = (payload) => (
    {
        type:UPDATE_RETURN_PACKAGE_FAILED,
        payload
    }
)
export const updateReturnEvent = (payload) => (
    {
        type:UPDATE_RETURN_EVENT,
        payload
    }
)
export const updateReturnEventSuccess = (payload) => (
    {
        type: UPDATE_RETURN_EVENT_SUCCESS,
        payload
    }
)
export const updateReturnEventFailed = (payload) => (
    {
        type:UPDATE_RETURN_EVENT_FAILED,
        payload
    }
)
export const exportErrorPackage = (payload) => (
    {
        type: EXPORT_ERROR_PACKAGE,
        payload
    }
)

export const exportErrorPackageSuccess = (payload) => (
    {
        type: EXPORT_ERROR_PACKAGE_SUCCESS,
        payload
    }
)
export const exportErrorReturn = (payload) => (
    {
        type: EXPORT_ERROR_RETURN,
        payload
    }
)

export const exportErrorReturnSuccess = (payload) => (
    {
        type: EXPORT_ERROR_RETURN_SUCCESS,
        payload
    }
)