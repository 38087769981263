import {
  GET_PACKAGE_HISTORY,
  GET_PACKAGE_HISTORY_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  packageHistoryList: {},
  loading: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PACKAGE_HISTORY:
      return {
        ...state,
        loading: true
      }

    case GET_PACKAGE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        packageHistoryList: action.payload
      }

    default:
      return state
  }
}
