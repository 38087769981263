import React from 'react'
import { Route, Switch } from 'react-router-dom'

import asyncComponent from 'util/asyncComponent'

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}packages`} component={asyncComponent(() => import('./PackagesPage'))} />
      <Route path={`${match.url}package-detail/:id`} component={asyncComponent(() => import('./PackagesPage/PackageDetail'))} />
      <Route path={`${match.url}return-packages`} component={asyncComponent(() => import('./ReturnPackagesPage'))} />
      <Route path={`${match.url}return-package-detail/:id_return`} component={asyncComponent(() => import('./ReturnPackagesPage/ReturnPackageDetail'))} />
      <Route
        exact path={`${match.url}live-tracking-return-packages`}
        component={asyncComponent(() => import('./LiveTrackingReturnPackagesPage'))}
      />
      <Route
        exact path={`${match.url}shipment-provider`}
        component={asyncComponent(() => import('./ShipmentProviderPage/index'))}
      />

      <Route
        exact path={`${match.url}live-tracking-return-package-detail/:id`}
        component={asyncComponent(() => import('./ReturnPackagesPage/PackageDetail'))}
      />
      <Route
        exact path={`${match.url}live-tracking-package-detail/:id`}
        component={asyncComponent(() => import('./PackagesPage/LiveTrackingPackageDetail'))}
      />
      <Route
        exact path={`${match.url}hubcode`}
        component={asyncComponent(() => import('./Hubcode/Hubcode'))}
      />
      <Route
        exact path={`${match.url}hubcode/import`}
        component={asyncComponent(() => import('./Hubcode/HubcodeImport'))}
      />
      <Route
        exact path={`${match.url}hubcode/:id`}
        component={asyncComponent(() => import('./Hubcode/HubcodeForm'))}
      />
      <Route
        exact path={`${match.url}queue-job`}
        component={asyncComponent(() => import('./QueueJobs/QueueJobs'))}
      />
      <Route
        path={`${match.url}reset-password`}
        component={asyncComponent(() => import('./ResetPassword/ResetPassword'))}
      />
      <Route
        exact path={`${match.url}seller`}
        component={asyncComponent(() => import('./Seller3PL/Seller3PL'))}
      />
      <Route
        exact path={`${match.url}seller/:id/:id_provider_account/:fk_provider`}
        component={asyncComponent(() => import('./Seller3PL/Seller3PLForm'))}
      />
      <Route
        exact path={`${match.url}mapping-status`}
        component={asyncComponent(() => import('./MappingStatus/index'))}
      />
      <Route
        exact path={`${match.url}mp-seller-account`}
        component={asyncComponent(() => import('./MpSellerAccountPage/index'))}
      />
      <Route
        exact path={`${match.url}location-mapping`}
        component={asyncComponent(() => import('./LocationMappingPage/index'))}
      />
      <Route
        exact path={`${match.url}location-mapping/import`}
        component={asyncComponent(() => import('./LocationMappingPage/ImportFile'))}
      />
      <Route
        exact path={`${match.url}package-history`}
        component={asyncComponent(() => import('./PackageHistory/index'))}
      />
      <Route
        exact path={`${match.url}mapping-status/import`}
        component={asyncComponent(() => import('./MappingStatus/ImportFile'))}
      />
      <Route
        exact path={`${match.url}unmapped-status`}
        component={asyncComponent(() => import('./UnmappedStatus/index'))}
      />
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import('./Dashboard'))}
      />
      <Route
          path={`${match.url}dashboard-error`}
          component={asyncComponent(() => import('./Dashboard/DashboardError'))}
      />
      <Route
        path={`${match.url}dashboard-sync-time`}
        component={asyncComponent(() => import('./Dashboard/IndexSyncTime'))}
      />

      <Route path={`${match.url}dashboard-detail/:type/:date`} component={asyncComponent(() => import('./Dashboard/DashboardDetail'))} />
      <Route path={`${match.url}ErrorPages/403`} component={asyncComponent(() => import('./ErrorPages/403/index'))} />
      <Route path={`${match.url}ErrorPages/401`} component={asyncComponent(() => import('./ErrorPages/401/index'))} />
      <Route
        path={`${match.url}oms-api`}
        component={asyncComponent(() => import('./ApiDocument/Oms/OmsApi'))}
      />
      <Route
        path={`${match.url}bumblebee-api`}
        component={asyncComponent(() => import('./ApiDocument/Bumblebee/BumblebeeApi'))}
      />
      <Route
        path={`${match.url}cronjob`}
        component={asyncComponent(() => import('./Cronjob'))}
      />
      <Route
        exact path={`${match.url}error-data`}
        component={asyncComponent(() => import('./ErrorData/index'))}
      />
      <Route
        exact path={`${match.url}user-management`}
        component={asyncComponent(() => import('./UserManagement/index'))}
      />
      <Route
        exact path={`${match.url}export-update-data`}
        component={asyncComponent(() => import('./ExportUpdateData/index'))}
      />
      <Route
        exact path={`${match.url}menu-settings`}
        component={asyncComponent(() => import('./MenuSidebarSettings/index'))}
      />
      <Route
        path='*'
        component={asyncComponent(() => import('./ErrorPages/404/index'))}
      />
    </Switch>
  </div>
)

export default App
