import {
  GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES,
  GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS,
} from '../../../constants/ActionTypes'

const INIT_STATE = {
  top3PLReturnPackages: {},
  totalNumberReturnPackages: 0,
  loadingReturnPackages: false,
  headerTotalNumberReturnPackages: 0,
  loadingTotalReturnPackages: false,
  headerTotalNumberErrorReturnPackages: 0,
  top3PLErrorReturnPackages: {},
  loadingErrorReturnPackages: false,
  totalNumberErrorReturnPackages: 0,
  loadingTotalErrorReturnPackages: false,
  headerTotalNumberLiveTrackingErrorReturnPackages: 0,
  top3PLLiveTrackingErrorReturnPackages: {},
  loadingLiveTrackingErrorReturnPackages: false,
  totalNumberLiveTrackingErrorReturnPackages: 0,
  loadingTotalLiveTrackingErrorReturnPackages: false,
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Return Packages
    case GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES:
      return {
        ...state,
        loadingReturnPackages: true
      }

    case GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingReturnPackages: false,
        top3PLReturnPackages: action.payload.top3PLReturnPackages,
        totalNumberReturnPackages: action.payload.totalNumberReturnPackages
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES:
      return {
        ...state,
        loadingTotalReturnPackages: true
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingTotalReturnPackages: false,
        headerTotalNumberReturnPackages: action.payload
      }

    // Error Return Packages
    case GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES:
      return {
        ...state,
        loadingErrorReturnPackages: true
      }

    case GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingErrorReturnPackages: false,
        top3PLErrorReturnPackages: action.payload.top3PLErrorReturnPackages,
        totalNumberErrorReturnPackages: action.payload.totalNumberErrorReturnPackages
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES:
      return {
        ...state,
        loadingTotalErrorReturnPackages: true
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingTotalErrorReturnPackages: false,
        headerTotalNumberErrorReturnPackages: action.payload
      }

    // Live Tracking Error Return Packages
    case GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES:
      return {
        ...state,
        loadingLiveTrackingErrorReturnPackages: true
      }

    case GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingLiveTrackingErrorReturnPackages: false,
        top3PLLiveTrackingErrorReturnPackages: action.payload.top3PLLiveTrackingErrorReturnPackages,
        totalNumberLiveTrackingErrorReturnPackages: action.payload.totalNumberLiveTrackingErrorReturnPackages
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES:
      return {
        ...state,
        loadingTotalLiveTrackingErrorReturnPackages: true
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingTotalLiveTrackingErrorReturnPackages: false,
        headerTotalNumberLiveTrackingErrorReturnPackages: action.payload
      }

    default:
      return state
  }
}
