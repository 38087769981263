import { exportDashboardSuccess } from '../appRedux/actions'

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU'
export const WINDOW_WIDTH = 'WINDOW-WIDTH'

//Contact Module const
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER'
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER'

//Auth const
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER'
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS'
export const SIGNIN_USER = 'SIGNIN_USER'
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS'
export const SIGNOUT_USER = 'SIGNOUT_USER'
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS'
export const INIT_URL = 'INIT_URL'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'

//Packages const
export const GET_PACKAGES = 'GET_PACKAGES'
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS'
export const GET_PACKAGE_DETAIL = 'GET_PACKAGE_DETAIL'
export const GET_PACKAGE_DETAIL_SUCCESS = 'GET_PACKAGE_DETAIL_SUCCESS'
export const GET_PACKAGES_LOG = 'GET_PACKAGES_LOG'
export const GET_PACKAGES_LOG_SUCCESS = 'GET_PACKAGES_LOG_SUCCESS'
export const GET_LIVE_TRACKING_PACKAGE_DETAIL = 'GET_LIVE_TRACKING_PACKAGE_DETAIL';
export const GET_LIVE_TRACKING_PACKAGE_DETAIL_SUCCESS = 'GET_LIVE_TRACKING_PACKAGE_DETAIL_SUCCESS';


//Log const
export const GET_FAREYE_TRACKING_LOG = 'GET_FAREYE_TRACKING'
export const GET_FAREYE_TRACKING_LOG_SUCCESS = 'GET_FAREYE_TRACKING_SUCCESS'

//Hubcode const
export const GET_HUB_CODE = 'GET_HUB_CODE'
export const GET_HUB_CODE_SUCCESS = 'GET_HUB_CODE_SUCCESS'
export const GET_HUB_CODE_DETAIL = 'GET_HUB_CODE_DETAIL'
export const GET_HUB_CODE_DETAIL_SUCCESS = 'GET_HUB_CODE_DETAIL_SUCCESS'
export const DELETE_HUB_CODE = 'DELETE_HUB_CODE'
export const DELETE_HUB_CODE_SUCCESS = 'DELETE_HUB_CODE_SUCCESS'
export const EDIT_HUB_CODE = 'EDIT_HUB_CODE'
export const EDIT_HUB_CODE_SUCCESS = 'EDIT_HUB_CODE_SUCCESS'
export const IMPORT_HUB_CODE = 'IMPORT_HUB_CODE'
export const IMPORT_HUB_CODE_SUCCESS = 'IMPORT_HUB_CODE_SUCCESS'

//Queue jobs const
export const GET_QUEUE_JOBS = 'GET_QUEUE_JOBS'
export const GET_QUEUE_JOBS_SUCCESS = 'GET_QUEUE_JOBS_SUCCESS'

//LocationMapping
export const GET_LOCATION_MAPPING_TABLE = 'GET_LOCATION_MAPPING_TABLE'
export const GET_LOCATION_MAPPING_TABLE_SUCCESS = 'GET_LOCATION_MAPPING_TABLE_SUCCESS'
export const GET_LOCATION_MAPPING = 'GET_LOCATION_MAPPING'
export const GET_LOCATION_MAPPING_SUCCESS = 'GET_LOCATION_MAPPING_SUCCESS'
export const EDIT_LOCATION_MAPPING = 'EDIT_LOCATION_MAPPING'
export const EDIT_LOCATION_MAPPING_SUCCESS = 'EDIT_LOCATION_MAPPING_SUCCESS'
export const EDIT_LOCATION_MAPPING_FAILED = 'EDIT_LOCATION_MAPPING_FAILED'
export const ADD_LOCATION_MAPPING = 'ADD_LOCATION_MAPPING'
export const ADD_LOCATION_MAPPING_SUCCESS = 'ADD_LOCATION_MAPPING_SUCCESS'
export const ADD_LOCATION_MAPPING_FAILED = 'ADD_LOCATION_MAPPING_FAILED'
export const DELETE_LOCATION_MAPPING = 'DELETE_LOCATION_MAPPING'
export const DELETE_LOCATION_MAPPING_SUCCESS = 'DELETE_LOCATION_MAPPING_SUCCESS'
export const DELETE_LOCATION_MAPPING_FAILED = 'DELETE_LOCATION_MAPPING_FAILED'
export const IMPORT_LOCATION_MAPPING = 'IMPORT_LOCATION_MAPPING'
export const IMPORT_LOCATION_MAPPING_SUCCESS = 'IMPORT_LOCATION_MAPPING_SUCCESS'
export const IMPORT_LOCATION_MAPPING_FAILED = 'IMPORT_LOCATION_MAPPING_FAILED'
export const EXPORT_LOCATION_MAPPING = 'EXPORT_LOCATION_MAPPING'
export const EXPORT_LOCATION_MAPPING_SUCCESS = 'EXPORT_LOCATION_MAPPING_SUCCESS'

//Seller3PL const
export const GET_3PL_SELLER = 'GET_3PL_SELLER'
export const GET_3PL_SELLER_SUCCESS = 'GET_3PL_SELLER_SUCCESS'
export const EDIT_3PL_SELLER = 'EDIT_3PL_SELLER'
export const EDIT_3PL_SELLER_SUCCESS = 'EDIT_3PL_SELLER_SUCCESS'
export const EDIT_3PL_SELLER_FAILED = 'EDIT_3PL_SELLER_FAILED'
export const SHOW_MESSAGE_3PL_SELLER = 'SHOW_MESSAGE_3PL_SELLER'
export const HIDE_MESSAGE_3PL_SELLER = 'HIDE_MESSAGE_3PL_SELLER'
export const GET_3PL_SELLER_DETAIL = 'GET_3PL_SELLER_DETAIL'
export const GET_3PL_SELLER_DETAIL_SUCCESS = 'GET_3PL_SELLER_DETAIL_SUCCESS'

//Mapping Status const
export const GET_MAPPING_STATUS = 'GET_MAPPING_STATUS'
export const GET_MAPPING_STATUS_SUCCESS = 'GET_MAPPING_STATUS_SUCCESS'
export const EDIT_MAPPING_STATUS = 'EDIT_MAPPING_STATUS'
export const EDIT_MAPPING_STATUS_SUCCESS = 'EDIT_MAPPING_STATUS_SUCCESS'
export const EDIT_MAPPING_STATUS_FAILED = 'EDIT_MAPPING_STATUS_FAILED'
export const EDIT_CUSTOM_DATA_FIELDS = 'EDIT_CUSTOM_DATA_FIELDS'
export const EDIT_CUSTOM_DATA_FIELDS_SUCCESS = 'EDIT_CUSTOM_DATA_FIELDS_SUCCESS'
export const EDIT_CUSTOM_DATA_FIELDS_FAILED = 'EDIT_CUSTOM_DATA_FIELDS_FAILED'
export const GET_BUMBLEBEE_STATUS_LIST ='GET_BUMBLEBEE_STATUS_LIST'
export const GET_BUMBLEBEE_FORWARD_STATUS = 'GET_BUMBLEBEE_FORWARD_STATUS'
export const GET_BUMBLEBEE_FORWARD_STATUS_SUCCESS = 'GET_BUMBLEBEE_FORWARD_STATUS_SUCCESS'
export const GET_BUMBLEBEE_RETURN_STATUS = 'GET_BUMBLEBEE_RETURN_STATUS'
export const GET_BUMBLEBEE_RETURN_STATUS_SUCCESS = 'GET_BUMBLEBEE_RETURN_STATUS_SUCCESS'
export const GET_BUMBLEBEE_STATUS_LIST_SUCCESS = 'GET_BUMBLEBEE_STATUS_LIST_SUCCESS'
export const ADD_MAPPING_STATUS = 'ADD_MAPPING_STATUS'
export const ADD_MAPPING_STATUS_SUCCESS = 'ADD_MAPPING_STATUS_SUCCESS'
export const ADD_MAPPING_STATUS_FAILED = 'ADD_MAPPING_STATUS_FAILED'
export const DELETE_MAPPING_STATUS = 'DELETE_MAPPING_STATUS'
export const DELETE_MAPPING_STATUS_SUCCESS = 'DELETE_MAPPING_STATUS_SUCCESS'
export const DELETE_MAPPING_STATUS_FAILED = 'DELETE_MAPPING_STATUS_FAILED'
export const IMPORT_MAPPING_STATUS = 'IMPORT_MAPPING_STATUS'
export const IMPORT_MAPPING_STATUS_SUCCESS = 'IMPORT_MAPPING_STATUS_SUCCESS'
export const IMPORT_MAPPING_STATUS_FAILED = 'IMPORT_MAPPING_STATUS_FAILED'
export const RESET = 'RESET'
export const DOWNLOAD_EXAMPLE = 'DOWNLOAD_EXAMPLE'
export const DOWNLOAD_EXAMPLE_SUCCESS = 'DOWNLOAD_EXAMPLE_SUCCESS'
export const EXPORT_MAPPING_STATUS = 'EXPORT_MAPPING_STATUS'
export const EXPORT_MAPPING_STATUS_SUCCESS = 'EXPORT_MAPPING_STATUS_SUCCESS'


//Mapping Status const
export const GET_MP_SELLER = 'GET_MP_SELLER'
export const GET_MP_SELLER_SUCCESS = 'GET_MP_SELLER_SUCCESS'
export const EDIT_MP_SELLER = 'EDIT_MP_SELLER'
export const EDIT_MP_SELLER_SUCCESS = 'EDIT_MP_SELLER_SUCCESS'
export const EDIT_MP_SELLER_FAILED = 'EDIT_MP_SELLER_FAILED'
export const ADD_MP_SELLER = 'ADD_MP_SELLER'
export const ADD_MP_SELLER_SUCCESS = 'ADD_MP_SELLER_SUCCESS'
export const ADD_MP_SELLER_FAILED = 'ADD_MP_SELLER_FAILED'
export const DELETE_MP_SELLER = 'DELETE_MP_SELLER'
export const DELETE_MP_SELLER_SUCCESS = 'DELETE_MP_SELLER_SUCCESS'
export const DELETE_MP_SELLER_FAILED = 'DELETE_MP_SELLER_FAILED'


//Return package const
export const GET_RETURN_PACKAGES = 'GET_RETURN_PACKAGES'
export const GET_RETURN_PACKAGES_SUCCESS = 'GET_RETURN_PACKAGES_SUCCESS'
export const GET_RETURN_PACKAGE_LOG = 'GET_RETURN_PACKAGE_LOG'
export const GET_RETURN_PACKAGE_LOG_SUCCESS = 'GET_RETURN_PACKAGE_LOG_SUCCESS'
export const GET_RETURN_PACKAGE_DETAIL = 'GET_RETURN_PACKAGE_DETAIL'
export const GET_RETURN_PACKAGE_DETAIL_SUCCESS = 'GET_RETURN_PACKAGE_DETAIL_SUCCESS'
export const GET_LIVE_TRACKING_RETURN_PACKAGES = 'GET_LIVE_TRACKING_RETURN_PACKAGES'
export const GET_LIVE_TRACKING_RETURN_PACKAGES_SUCCESS = 'GET_LIVE_TRACKING_RETURN_PACKAGES_SUCCESS'
export const GET_LIVE_TRACKING_RETURN_PACKAGES_LOG = 'GET_LIVE_TRACKING_RETURN_PACKAGES_LOG'
export const GET_LIVE_TRACKING_RETURN_PACKAGES_LOG_SUCCESS = 'GET_LIVE_TRACKING_RETURN_PACKAGES_LOG_SUCCESS'
export const GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL = 'GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL'
export const GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL_SUCCESS = 'GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL_SUCCESS'

//Provider const
export const GET_PROVIDERS = 'GET_PROVIDERS'
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS'
export const GET_PROVIDERS_BY_COUNTRY = 'GET_PROVIDERS_BY_COUNTRY'
export const GET_PROVIDERS_BY_COUNTRY_SUCCESS = 'GET_PROVIDERS_BY_COUNTRY_SUCCESS'
export const GET_PROVIDER_SELLERS_BY_COUNTRY = 'GET_PROVIDER_SELLERS_BY_COUNTRY'
export const GET_PROVIDER_SELLERS_BY_COUNTRY_SUCCESS = 'GET_PROVIDER_SELLERS_BY_COUNTRY_SUCCESS'
export const GET_PROVIDERS_PAGINATION = 'GET_PROVIDERS_PAGINATION'
export const GET_PROVIDERS_PAGINATION_SUCCESS = 'GET_PROVIDERS_PAGINATION_SUCCESS'
export const UPDATE_PROVIDER_BY_ID = 'UPDATE_PROVIDER_BY_ID'
export const UPDATE_PROVIDER_BY_ID_FAIL = 'UPDATE_PROVIDER_BY_ID_FAIL'
export const UPDATE_PROVIDER_BY_ID_SUCCESS = 'UPDATE_PROVIDER_BY_ID_SUCCESS'

//Dashboard const
export const GET_DASHBOARD = 'GET_DASHBOARD'
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_SYNC_TIME = 'GET_DASHBOARD_SYNC_TIME'
export const GET_DASHBOARD_SYNC_TIME_SUCCESS = 'GET_DASHBOARD_SYNC_TIME_SUCCESS'
export const EXPORT_DASHBOARD = 'EXPORT_DASHBOARD'
export const EXPORT_DASHBOARD_SUCCESS = 'EXPORT_DASHBOARD_SUCCESS'
// Dashboard Packages
export const GET_DASHBOARD_TOP_3PL_PACKAGES = 'GET_DASHBOARD_TOP_3PL_PACKAGES'
export const GET_DASHBOARD_TOP_3PL_PACKAGES_SUCCESS = 'GET_DASHBOARD_TOP_3PL_PACKAGES_SUCCESS'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES_SUCCESS = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES_SUCCESS'
// Dashboard Error Packages
export const GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES = 'GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES'
export const GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES_SUCCESS = 'GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES_SUCCESS'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES_SUCCESS = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES_SUCCESS'
// Dashboard Live Tracking Error Packages
export const GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES = 'GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES'
export const GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS = 'GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS'
// Dashboard Return Packages
export const GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES = 'GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES'
export const GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES_SUCCESS = 'GET_DASHBOARD_TOP_3PL_RETURN_PACKAGES_SUCCESS'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES_SUCCESS = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_RETURN_PACKAGES_SUCCESS'
// Dashboard Error Return Packages
export const GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES = 'GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES'
export const GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES_SUCCESS = 'GET_DASHBOARD_TOP_3PL_ERROR_RETURN_PACKAGES_SUCCESS'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES_SUCCESS = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_RETURN_PACKAGES_SUCCESS'
// Dashboard Live Tracking Error Return Packages
export const GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES = 'GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES'
export const GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS = 'GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_RETURN_PACKAGES_SUCCESS'
// Dashboard Cancel Packages
export const GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES = 'GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES'
export const GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES_SUCCESS = 'GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES_SUCCESS'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES_SUCCESS = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES_SUCCESS'
// Dashboard Error Cancel Packages
export const GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES = 'GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES'
export const GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES_SUCCESS = 'GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES_SUCCESS'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES_SUCCESS = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES_SUCCESS'
// Dashboard User Login
export const GET_DASHBOARD_TOP_USERS_LOGIN = 'GET_DASHBOARD_TOP_USERS_LOGIN'
export const GET_DASHBOARD_TOP_USERS_LOGIN_SUCCESS = 'GET_DASHBOARD_TOP_USERS_LOGIN_SUCCESS'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN'
export const GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN_SUCCESS = 'GET_DASHBOARD_HEADER_TOTAL_NUMBER_USER_LOGIN_SUCCESS'

//Document API const
export const GET_OMS_API_DOCUMENT = 'GET_OMS_API_DOCUMENT'
export const GET_OMS_API_DOCUMENT_SUCCESS = 'GET_OMS_API_DOCUMENT_SUCCESS'
export const GET_BUMBLEBEE_API_DOCUMENT = 'GET_BUMBLEBEE_API_DOCUMENT'
export const GET_BUMBLEBEE_API_DOCUMENT_SUCCESS = 'GET_BUMBLEBEE_API_DOCUMENT_SUCCESS'
export const UPDATE_BUMBLEBEE_API_DOCUMENT_DATA = 'UPDATE_BUMBLEBEE_API_DOCUMENT_DATA'
export const UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_SUCCESS = 'UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_SUCCESS'
export const UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_FAIL = 'UPDATE_BUMBLEBEE_API_DOCUMENT_DATA_FAIL'

//Package Cancellation Log const
export const GET_PACKAGE_CANCELLATION_LOG = 'GET_PACKAGE_CANCELLATION_LOG'
export const GET_PACKAGE_CANCELLATION_LOG_SUCCESS = 'GET_PACKAGE_CANCELLATION_LOG_SUCCESS'
export const SHOW_PACKAGE_CANCELLATION_MESSAGE = 'SHOW_PACKAGE_CANCELLATION_MESSAGE';
export const HIDE_PACKAGE_CANCELLATION_MESSAGE = 'HIDE_PACKAGE_CANCELLATION_MESSAGE';

//Cronjob
export const GET_CRONJOB_STATUS = 'GET_CRONJOB_STATUS'
export const GET_CRONJOB_STATUS_SUCCESS = 'GET_CRONJOB_STATUS_SUCCESS'
export const TRIGGER_CRONJOB = 'TRIGGER_CRONJOB'
export const TRIGGER_CRONJOB_SUCCESS = 'TRIGGER_CRONJOB_SUCCESS'
export const TRIGGER_CRONJOB_FAIL = 'TRIGGER_CRONJOB_FAIL'
export const SHOW_LOG_DATA = 'SHOW_LOG_DATA'
export const HIDE_LOG_DATA = 'HIDE_LOG_DATA'

//unmapped status
export const GET_UNMAPPED_STATUS = 'GET_UNMAPPED_STATUS'
export const GET_UNMAPPED_STATUS_SUCCESS = 'GET_UNMAPPED_STATUS_SUCCESS'

// Package History
export const GET_PACKAGE_HISTORY = 'GET_PACKAGE_HISTORY'
export const GET_PACKAGE_HISTORY_SUCCESS = 'GET_PACKAGE_HISTORY_SUCCESS'

// Error Data
export const GET_ERROR_DATA = 'GET_ERROR_DATA'
export const GET_ERROR_DATA_SUCCESS = 'GET_ERROR_DATA_SUCCESS'

// User Management
export const GET_USERS_LIST = 'GET_USERS_LIST'
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

// Update Extract Data
export const EXPORT_PACKAGE = 'EXPORT_PACKAGE'
export const EXPORT_PACKAGE_SUCCESS = 'EXPORT_PACKAGE_SUCCESS'
export const EXPORT_ERROR_PACKAGE = 'EXPORT_ERROR_PACKAGE'
export const EXPORT_ERROR_PACKAGE_SUCCESS = 'EXPORT_ERROR_PACKAGE_SUCCESS'
export const EXPORT_ERROR_RETURN = 'EXPORT_ERROR_RETURN'
export const EXPORT_ERROR_RETURN_SUCCESS = 'EXPORT_ERROR_PACKAGE_SUCCESS'
export const EXPORT_RETURN = 'EXPORT_RETURN'
export const EXPORT_RETURN_SUCCESS = 'EXPORT_RETURN_SUCCESS'
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE'
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS'
export const UPDATE_PACKAGE_FAILED = 'UPDATE_PACKAGE_FAILED'
export const UPDATE_RETURN_PACKAGE = 'UPDATE_RETURN_PACKAGE'
export const UPDATE_RETURN_PACKAGE_SUCCESS = 'UPDATE_RETURN_PACKAGE_SUCCESS'
export const UPDATE_RETURN_PACKAGE_FAILED = 'UPDATE_RETURN_PACKAGE_FAILED'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS'
export const UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED'
export const UPDATE_RETURN_EVENT = 'UPDATE_RETURN_EVENT'
export const UPDATE_RETURN_EVENT_SUCCESS = 'UPDATE_RETURN_EVENT_SUCCESS'
export const UPDATE_RETURN_EVENT_FAILED = 'UPDATE_RETURN_EVENT_FAILED'
// Menu Sidebar Setting
export const GET_MENU_LIST = 'GET_MENU_LIST'
export const GET_MENU_LIST_SUCCESS = 'GET_MENU_LIST_SUCCESS'
export const UPDATE_MENU_LIST = 'UPDATE_MENU_LIST'
export const UPDATE_MENU_LIST_SUCCESS = 'UPDATE_MENU_LIST_SUCCESS'
export const UPDATE_MENU_LIST_FAIL = 'UPDATE_MENU_LIST_FAIL'
