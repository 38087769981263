import {
  GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES,
  GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES,
  GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES_SUCCESS
} from '../../../constants/ActionTypes'

const INIT_STATE = {
  headerTotalNumberCancelPackages: 0,
  top3PLCancelPackages: {},
  totalNumberCancelPackages: 0,
  headerTotalNumberErrorCancelPackages: 0,
  top3PLErrorCancelPackages: {},
  totalNumberErrorCancelPackages: 0,
  loadingCancelPackages: false,
  loadingTotalCancelPackages: false,
  loadingErrorCancelPackages: false,
  loadingTotalErrorCancelPackages: false,
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // Cancel Packages
    case GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES:
      return {
        ...state,
        loadingCancelPackages: true
      }

    case GET_DASHBOARD_TOP_3PL_CANCEL_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingCancelPackages: false,
        top3PLCancelPackages: action.payload.top3PLCancelPackages,
        totalNumberCancelPackages: action.payload.totalNumberCancelPackages
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES:
      return {
        ...state,
        loadingTotalCancelPackages: true
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_CANCEL_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingTotalCancelPackages: false,
        headerTotalNumberCancelPackages: action.payload
      }

    // Error Cancel Packages
    case GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES:
      return {
        ...state,
        loadingErrorCancelPackages: true
      }

    case GET_DASHBOARD_TOP_3PL_ERROR_CANCEL_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingErrorCancelPackages: false,
        top3PLErrorCancelPackages: action.payload.top3PLErrorCancelPackages,
        totalNumberErrorCancelPackages: action.payload.totalNumberErrorCancelPackages
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES:
      return {
        ...state,
        loadingTotalErrorCancelPackages: true
      }

    case GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_CANCEL_PACKAGES_SUCCESS:
      return {
        ...state,
        loadingTotalErrorCancelPackages: false,
        headerTotalNumberErrorCancelPackages: action.payload
      }

    default:
      return state
  }
}
