import { takeEvery, call, all, fork, put } from 'redux-saga/effects'

import {
  GET_LOCATION_MAPPING_TABLE,
  GET_LOCATION_MAPPING,
  EDIT_LOCATION_MAPPING,
  ADD_LOCATION_MAPPING,
  DELETE_LOCATION_MAPPING,
  IMPORT_LOCATION_MAPPING,
  EXPORT_LOCATION_MAPPING
} from '../../constants/ActionTypes'
import {
  getLocationMappingTableSuccess,
  getLocationMappingSuccess,
  editLocationMappingSuccess,
  editLocationMappingFailed,
  addLocationMappingFailed,
  addLocationMappingSuccess,
  deleteLocationMappingFailed,
  deleteLocationMappingSuccess,
  importLocationMappingSuccess,
  importLocationMappingFailed,
  exportLocationMappingSuccess,
} from '../actions/LocationMapping'

import { callApi, callApiGetFile} from '../../util/api'

const fetchLocationMappingTableRequest = (payload) => callApi(
  '/api/v1.0/location-mapping/table-index',
  'GET',
  payload
)

function* fetchLocationMappingTable({ payload }) {
  const res = yield call(fetchLocationMappingTableRequest, payload)
  yield put(getLocationMappingTableSuccess(res))
}

export function* getLocationMappingTableWatcher() {
  yield takeEvery(GET_LOCATION_MAPPING_TABLE, fetchLocationMappingTable)
}

const fetchLocationMappingRequest = (payload) => callApi(
  '/api/v1.0/location-mapping',
  'GET',
  payload
)

function* fetchLocationMapping({ payload }) {
  const res = yield call(fetchLocationMappingRequest, payload)
  yield put(getLocationMappingSuccess(res))
}

export function* getLocationMappingWatcher() {
  yield takeEvery(GET_LOCATION_MAPPING, fetchLocationMapping)
}

const editLocationMappingRequest = (payload) => callApi(
  '/api/v1.0/location-mapping/edit',
  'PUT',
  payload
)

function* editLocationMapping({ payload }) {
  const res = yield call(editLocationMappingRequest, payload)
  res && res.isSuccess ? yield put(editLocationMappingSuccess(res))
    : yield put(editLocationMappingFailed(res))
}

export function* editLocationMappingWatcher() {
  yield takeEvery(EDIT_LOCATION_MAPPING, editLocationMapping)
}

const addLocationMappingRequest = (payload) => callApi(
  '/api/v1.0/location-mapping/add',
  'POST',
  payload
)

function* addLocationMapping({ payload }) {
  const res = yield call(addLocationMappingRequest, payload)
  res && res.isSuccess ? yield put(addLocationMappingSuccess(res))
    : yield put(addLocationMappingFailed(res))
}

export function* addLocationMappingWatcher() {
  yield takeEvery(ADD_LOCATION_MAPPING, addLocationMapping)
}

const deleteLocationMappingRequest = (payload) => callApi(
  '/api/v1.0/location-mapping/delete',
  'POST',
  payload
)

function* deleteLocationMapping({ payload }) {
  const res = yield call(deleteLocationMappingRequest, payload)
  res && res.isSuccess ? yield put(deleteLocationMappingSuccess(res))
    : yield put(deleteLocationMappingFailed(res))
}

export function* deleteLocationMappingWatcher() {
  yield takeEvery(DELETE_LOCATION_MAPPING, deleteLocationMapping)
}

const importLocationMappingRequest = (payload) => callApi(
  '/api/v1.0/file-import/import-location-mapping',
  'POST',
  payload,
  'upload'
)

function* importLocationMapping({ payload }) {
  const res = yield call(importLocationMappingRequest, payload)
  res && res.isSuccess ? yield put(importLocationMappingSuccess(res))
    : yield put(importLocationMappingFailed(res))
}

export function* importLocationMappingWatcher() {
  yield takeEvery(IMPORT_LOCATION_MAPPING, importLocationMapping)
}

const fetchExportLocationMappingRequest = (payload) => callApiGetFile(
  '/api/v1.0/location-mapping/export',
  'GET',
  payload,
)

function* fetchExportLocationMapping({ payload }) {
  const fetchedCSV = yield call(fetchExportLocationMappingRequest, payload)
  yield put(exportLocationMappingSuccess(fetchedCSV))
}

export function* exportLocationMappingWatcher() {
  yield takeEvery(EXPORT_LOCATION_MAPPING, fetchExportLocationMapping)
}

export default function* rootSaga() {
  yield all([
    fork(getLocationMappingTableWatcher),
    fork(getLocationMappingWatcher),
    fork(editLocationMappingWatcher),
    fork(addLocationMappingWatcher),
    fork(deleteLocationMappingWatcher),
    fork(importLocationMappingWatcher),
    fork(exportLocationMappingWatcher),
  ])
}







