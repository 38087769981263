import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {GET_RETURN_PACKAGE_DETAIL, GET_RETURN_PACKAGE_LOG, GET_RETURN_PACKAGES, GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL} from '../../constants/ActionTypes'
import { getReturnPackageLogSuccess, getReturnPackagesSuccess, getReturnPackageDetailSuccess, getLiveTrackingReturnPackageDetailSuccess } from '../actions/ReturnPackages'

import { callApi } from '../../util/api'

const fetchReturnPackageLogRequest = (payload) => callApi(
  '/api/v1.0/return-package',
  'GET',
  payload
)

const fetchReturnPackagesRequest = (payload) => callApi(
  '/api/v1.0/return-package/get-return-package',
  'GET',
  payload
)

const fetchReturnPackageDetailRequest = (payload) => callApi(
  '/api/v1.0/return-package/get',
  'GET',
  payload
)

const fetchLiveTrackingReturnPackageDetailRequest = (payload) => callApi(
  '/api/v1.0/return-package/getlivetrackingdetail',
  'GET',
  payload
)

function* fetchReturnPackageLog({ payload }) {
  const fetchedPackages = yield call(fetchReturnPackageLogRequest, payload)
  yield put(getReturnPackageLogSuccess(fetchedPackages))
}

function* fetchReturnPackages({ payload }) {
  const fetchedPackages = yield call(fetchReturnPackagesRequest, payload)
  yield put(getReturnPackagesSuccess(fetchedPackages))
}

function* fetchReturnPackageDetail({ payload }) {
  const fetchedPackages = yield call(fetchReturnPackageDetailRequest, payload)
  yield put(getReturnPackageDetailSuccess(fetchedPackages))
}

function* fetchLiveTrackingReturnPackageDetail({ payload }) {
  const fetchedLiveTrackingReturnPackageDetail = yield call(fetchLiveTrackingReturnPackageDetailRequest, payload)
  yield put(getLiveTrackingReturnPackageDetailSuccess(fetchedLiveTrackingReturnPackageDetail))
}

export function* fetchReturnPackageLogWatcher() {
  yield takeEvery(GET_RETURN_PACKAGE_LOG, fetchReturnPackageLog)
}

export function* fetchReturnPackagesWatcher() {
  yield takeEvery(GET_RETURN_PACKAGES, fetchReturnPackages)
}

export function* fetchReturnPackageDetailWatcher() {
  yield takeEvery(GET_RETURN_PACKAGE_DETAIL, fetchReturnPackageDetail)
}

export function* fetchLiveTrackingReturnPackageDetailWatcher() {
  yield takeEvery(GET_LIVE_TRACKING_RETURN_PACKAGE_DETAIL, fetchLiveTrackingReturnPackageDetail)
}

export default function* rootSaga() {
  yield all([
    fork(fetchReturnPackageLogWatcher),
    fork(fetchReturnPackagesWatcher),
    fork(fetchReturnPackageDetailWatcher),
    fork(fetchLiveTrackingReturnPackageDetailWatcher),
  ])
}
