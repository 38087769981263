import {
  GET_CRONJOB_STATUS,
  GET_CRONJOB_STATUS_SUCCESS, HIDE_MESSAGE,
  TRIGGER_CRONJOB_FAIL,
  TRIGGER_CRONJOB_SUCCESS, HIDE_LOG_DATA
} from '../../constants/ActionTypes'

const INIT_STATE = {
  cronjobStatus : {},
  loading: false,
  alertMessage: '',
  success: true,
  showMessage: false,
  logMessage: ' ',
  showLog: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CRONJOB_STATUS:
      return {
        ...state,
        loading: true
      }

    case GET_CRONJOB_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        cronjobStatus: action.payload
      }
    case TRIGGER_CRONJOB_SUCCESS: {
      return {
        ...state,
        alertMessage: action.payload.message,
        showMessage: true,
        success: true,
        loader: false,
        logMessage: action.payload.log,
        showLog: true
      }
    }

    case TRIGGER_CRONJOB_FAIL: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        success: false,
        loader: false
      }
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false
      }
    }

    case HIDE_LOG_DATA: {
      return {
        ...state,
        logMessage: '',
        showLog: false
      }
    }
    default:
      return state
  }
}
