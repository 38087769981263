import {
  GET_ERROR_DATA,
  GET_ERROR_DATA_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  errorDataList: {},
  loading: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ERROR_DATA:
      return {
        ...state,
        loading: true
      }

    case GET_ERROR_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorDataList: action.payload
      }

    default:
      return state
  }
}
