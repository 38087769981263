import {
  EDIT_3PL_SELLER,
  EDIT_3PL_SELLER_SUCCESS,
  GET_3PL_SELLER,
  GET_3PL_SELLER_SUCCESS,
  GET_3PL_SELLER_DETAIL,
  GET_3PL_SELLER_DETAIL_SUCCESS,
  HIDE_MESSAGE_3PL_SELLER,
  SHOW_MESSAGE_3PL_SELLER,
  EDIT_3PL_SELLER_FAILED
} from '../../constants/ActionTypes'

export const getSeller3PL = (payload) => (
  {
    type: GET_3PL_SELLER,
    payload
  }
)

export const getSeller3PLSuccess = (sellerList) => (
  {
    type: GET_3PL_SELLER_SUCCESS,
    payload: sellerList
  }
)
export const edit3PLSeller = (payload) => (
  {
    type: EDIT_3PL_SELLER,
    payload
  }
)

export const edit3PLSellerSuccess = (edited3PLSeller) => (
  {
    type: EDIT_3PL_SELLER_SUCCESS,
    payload: edited3PLSeller
  }
)

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE_3PL_SELLER,
    payload: message
  }
}

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE_3PL_SELLER,
  }
}

export const getSellerDetail = (payload) => (
  {
    type: GET_3PL_SELLER_DETAIL,
    payload
  }
)
export const getSellerDetailSuccess = (selectedSeller) => (
  {
    type: GET_3PL_SELLER_DETAIL_SUCCESS,
    payload: selectedSeller
  }
)

export const edit3PLSellerFailed = (message) => {
  return {
    type: EDIT_3PL_SELLER_FAILED,
    payload: message
  }
}
