import {
  GET_QUEUE_JOBS,
  GET_QUEUE_JOBS_SUCCESS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  queueJobsList: {},
  loading: false
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

  case GET_QUEUE_JOBS:
    return {
      ...state,
      loading: true
    }

  case GET_QUEUE_JOBS_SUCCESS:
    return {
      ...state,
      loading: false,
      queueJobsList: action.payload
    }

  default:
    return state
  }
}
