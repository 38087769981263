import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {
  GET_PACKAGE_HISTORY,
} from '../../constants/ActionTypes'
import {
  getPackageHistorySuccess,
} from '../actions/PackageHistory'

import { callApi } from '../../util/api'

const fetchPackageHistoryRequest = (payload) => callApi(
  '/api/v1.0/package-history',
  'GET',
  payload
)

function* fetchPackageHistory({ payload }) {
  const fetchedPackageHistory = yield call(fetchPackageHistoryRequest, payload)
  yield put(getPackageHistorySuccess(fetchedPackageHistory))
}

export function* fetchPackageHistoryWatcher() {
  yield takeEvery(GET_PACKAGE_HISTORY, fetchPackageHistory)
}

export default function* rootSaga() {
  yield all([
    fork(fetchPackageHistoryWatcher)
  ])
}
