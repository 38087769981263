import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {GET_PACKAGES, GET_PACKAGE_DETAIL, GET_PACKAGES_LOG, GET_LIVE_TRACKING_PACKAGE_DETAIL} from '../../constants/ActionTypes'
import { getPackagesSuccess, getPackageDetailSuccess, getPackagesLogSuccess, getLiveTrackingPackageDetailSuccess } from '../actions/Packages'

import { callApi } from '../../util/api'

const fetchPackagesRequest = (payload) => callApi(
  '/api/v1.0/package',
  'GET',
  payload
)

const fetchPackagesLogRequest = (payload) => callApi(
    '/api/v1.0/package/getlog',
    'GET',
    payload
)

function* fetchPackages({ payload }) {
  const fetchedPackages = yield call(fetchPackagesRequest, payload)
  yield put(getPackagesSuccess(fetchedPackages))
}

function* fetchPackagesLog({ payload }) {
    const fetchedPackagesLog = yield call(fetchPackagesLogRequest, payload)
    yield put(getPackagesLogSuccess(fetchedPackagesLog))
}

export function* fetchPackagesWatcher() {
  yield takeEvery(GET_PACKAGES, fetchPackages)
}

export function* fetchPackagesLogWatcher() {
    yield takeEvery(GET_PACKAGES_LOG, fetchPackagesLog)
}

const fetchPackageDetailRequest = (payload) => callApi(
    '/api/v1.0/package/get',
    'GET',
    payload
)

function* fetchPackageDetail({ payload }) {
  const fetchedPackageDetail = yield call(fetchPackageDetailRequest, payload)
  yield put(getPackageDetailSuccess(fetchedPackageDetail))
}

export function* fetchPackageDetailWatcher() {
  yield takeEvery(GET_PACKAGE_DETAIL, fetchPackageDetail)
}

const fetchLiveTrackingPackageDetailRequest = (payload) => callApi(
  '/api/v1.0/package/getlivetrackingdetail',
  'GET',
  payload
)

function* fetchLiveTrackingPackageDetail({ payload }) {
const fetchedLiveTrackingPackageDetail = yield call(fetchLiveTrackingPackageDetailRequest, payload)
yield put(getLiveTrackingPackageDetailSuccess(fetchedLiveTrackingPackageDetail))
}

export function* fetchLiveTrackingPackageDetailWatcher() {
yield takeEvery(GET_LIVE_TRACKING_PACKAGE_DETAIL, fetchLiveTrackingPackageDetail)
}

export default function* rootSaga() {
  yield all([
      fork(fetchPackagesWatcher),
      fork(fetchPackageDetailWatcher),
      fork(fetchLiveTrackingPackageDetailWatcher),
      fork(fetchPackagesLogWatcher)
  ])
}
