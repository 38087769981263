import { takeEvery, call, all, fork, put } from 'redux-saga/effects'
import {
  GET_OMS_API_DOCUMENT,
  GET_BUMBLEBEE_API_DOCUMENT,
  UPDATE_BUMBLEBEE_API_DOCUMENT_DATA
} from '../../constants/ActionTypes'
import {
  getOmsApiDocumentSuccess,
  getBumblebeeApiDocumentSuccess,
  updateBumblebeeApiDocumentDataSuccess,
  updateBumblebeeApiDocumentDataFail,
} from '../actions/ApiDocs'
import { callApi } from '../../util/api'

const fetchOmsApiDocumentsRequest = (payload) => callApi(
  '/api/v1.0/get-oms-api-document',
  'GET',
  payload
)

const fetchBumblebeeApiDocumentsRequest = (payload) => callApi(
  '/api/v1.0/get-bumblebee-api-document',
  'GET',
  payload
)

const updateBumblebeeApiDocumentDataRequest = (payload) => callApi(
  '/api/v1.0/document/updateSampleData',
  'POST',
  payload
)

function* fetchOmsApiDocument({ payload }) {
  const fetchedDocuments = yield call(fetchOmsApiDocumentsRequest, payload)
  yield put(getOmsApiDocumentSuccess(fetchedDocuments))
}

function* fetchBumblebeeApiDocument({ payload }) {
  const fetchedDocuments = yield call(fetchBumblebeeApiDocumentsRequest, payload)
  yield put(getBumblebeeApiDocumentSuccess(fetchedDocuments))
}

function* updateBumblebeeApiDocumentData({ payload }) {
  const response = yield call(updateBumblebeeApiDocumentDataRequest, payload)
  if (response.success) {
    yield put(updateBumblebeeApiDocumentDataSuccess(response))
  } else {
    yield put(updateBumblebeeApiDocumentDataFail(response))
  }

}

export function* fetchOmsApiDocumentsWatcher() {
  yield takeEvery(GET_OMS_API_DOCUMENT, fetchOmsApiDocument)
}

export function* fetchBumblebeeApiDocumentsWatcher() {
  yield takeEvery(GET_BUMBLEBEE_API_DOCUMENT, fetchBumblebeeApiDocument)
}

export function* updateBumblebeeApiDocumentDataWatcher() {
  yield takeEvery(UPDATE_BUMBLEBEE_API_DOCUMENT_DATA, updateBumblebeeApiDocumentData)
}
export default function* rootSaga() {
  yield all([
    fork(fetchOmsApiDocumentsWatcher),
    fork(fetchBumblebeeApiDocumentsWatcher),
    fork(updateBumblebeeApiDocumentDataWatcher)
  ])
}
