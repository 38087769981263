import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  EXPORT_DASHBOARD,
  EXPORT_DASHBOARD_SUCCESS,
  GET_DASHBOARD_SYNC_TIME,
  GET_DASHBOARD_SYNC_TIME_SUCCESS,
} from '../../../constants/ActionTypes'

const INIT_STATE = {
  packagesList: {},
  syncTimeList: {},
  loading: false,
  type: '',
  downloadFile: undefined
}


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_DASHBOARD:
      return {
        ...state,
        loading: true
      }

    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        packagesList: action.payload
      }

    case GET_DASHBOARD_SYNC_TIME:
      return {
        ...state,
        loading: true
      }

    case GET_DASHBOARD_SYNC_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        syncTimeList: action.payload
      }

    // Export
    case EXPORT_DASHBOARD:
      return {
        ...state,
        loading: true
      }

    case EXPORT_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadFile: action.payload,
      }

    default:
      return state
  }
}
