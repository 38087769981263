import {
  GET_HUB_CODE,
  GET_HUB_CODE_SUCCESS,
  GET_HUB_CODE_DETAIL,
  GET_HUB_CODE_DETAIL_SUCCESS,
  DELETE_HUB_CODE,
  DELETE_HUB_CODE_SUCCESS,
  EDIT_HUB_CODE,
  EDIT_HUB_CODE_SUCCESS,
  IMPORT_HUB_CODE,
  IMPORT_HUB_CODE_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER
} from '../../constants/ActionTypes'

export const getHubcode = (payload) => (
  {
    type: GET_HUB_CODE,
    payload
  }
)

export const getHubcodeSuccess = (hubcodeList) => (
  {
    type: GET_HUB_CODE_SUCCESS,
    payload: hubcodeList
  }
)

export const getHubcodeDetail = (payload) => (
  {
    type: GET_HUB_CODE_DETAIL,
    payload
  }
)

export const getHubcodeDetailSuccess = (selectedHubcode) => (
  {
    type: GET_HUB_CODE_DETAIL_SUCCESS,
    payload: selectedHubcode
  }
)

export const deleteHubcode = (payload) => (
  {
    type: DELETE_HUB_CODE,
    payload
  }
)

export const deleteHubcodeSuccess = (deletedHubcode) => (
  {
    type: DELETE_HUB_CODE_SUCCESS,
    payload: deletedHubcode
  }
)

export const editHubcode = (payload) => (
  {
    type: EDIT_HUB_CODE,
    payload
  }
)

export const editHubcodeSuccess = (editedHubcode) => (
  {
    type: EDIT_HUB_CODE_SUCCESS,
    payload: editedHubcode
  }
)

export const importHubcode = (payload) => (
  {
    type: IMPORT_HUB_CODE,
    payload
  }
)

export const importHubcodeSuccess = (payload) => (
  {
    type: IMPORT_HUB_CODE_SUCCESS,
    payload
  }
)

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
}

export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  }
}

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  }
}

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  }
}
