import {
  GET_DASHBOARD_TOP_3PL_PACKAGES,
  GET_DASHBOARD_TOP_3PL_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES,
  GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES_SUCCESS,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES,
  GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES,
  GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS
} from '../../../constants/ActionTypes'

// Packages
export const getDashboardTop3PLPackages = (payload) => (
  {
    type: GET_DASHBOARD_TOP_3PL_PACKAGES,
    payload
  }
)

export const getDashboardTop3PLPackagesSuccess = (top3PLPackages, totalNumberPackages) => (
  {
    type: GET_DASHBOARD_TOP_3PL_PACKAGES_SUCCESS,
    payload: { top3PLPackages, totalNumberPackages }
  }
)

export const getDashboardHeaderTotalNumberPackages = (payload) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES,
    payload
  }
)

export const getDashboardHeaderTotalNumberPackagesSuccess = (headerTotalNumberPackages) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_PACKAGES_SUCCESS,
    payload: headerTotalNumberPackages
  }
)

// Error Packages
export const getDashboardTop3PLErrorPackages = (payload) => (
  {
    type: GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES,
    payload
  }
)

export const getDashboardTop3PLErrorPackagesSuccess = (top3PLErrorPackages, totalNumberErrorPackages) => (
  {
    type: GET_DASHBOARD_TOP_3PL_ERROR_PACKAGES_SUCCESS,
    payload: { top3PLErrorPackages, totalNumberErrorPackages }
  }
)

export const getDashboardHeaderTotalNumberErrorPackages = (payload) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES,
    payload
  }
)

export const getDashboardHeaderTotalNumberErrorPackagesSuccess = (HeaderTotalNumberErrorPackages) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_ERROR_PACKAGES_SUCCESS,
    payload: HeaderTotalNumberErrorPackages
  }
)

// Live Tracking Error Packages
export const getDashboardTop3PLLiveTrackingErrorPackages = (payload) => (
  {
    type: GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES,
    payload
  }
)

export const getDashboardTop3PLLiveTrackingErrorPackagesSuccess = (top3PLLiveTrackingErrorPackages, totalNumberLiveTrackingErrorPackages) => (
  {
    type: GET_DASHBOARD_TOP_3PL_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS,
    payload: { top3PLLiveTrackingErrorPackages, totalNumberLiveTrackingErrorPackages }
  }
)

export const getDashboardHeaderTotalNumberLiveTrackingErrorPackages = (payload) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES,
    payload
  }
)

export const getDashboardHeaderTotalNumberLiveTrackingErrorPackagesSuccess = (HeaderTotalNumberLiveTrackingErrorPackages) => (
  {
    type: GET_DASHBOARD_HEADER_TOTAL_NUMBER_LIVE_TRACKING_ERROR_PACKAGES_SUCCESS,
    payload: HeaderTotalNumberLiveTrackingErrorPackages
  }
)
