import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGE_DETAIL,
  GET_PACKAGE_DETAIL_SUCCESS,
  GET_LIVE_TRACKING_PACKAGE_DETAIL,
  GET_LIVE_TRACKING_PACKAGE_DETAIL_SUCCESS,
  GET_PACKAGES_LOG,
  GET_PACKAGES_LOG_SUCCESS
} from '../../constants/ActionTypes'

export const getPackages = (payload) => (
  {
    type: GET_PACKAGES,
    payload
  }
)

export const getPackagesSuccess = (packagesList) => (
  {
    type: GET_PACKAGES_SUCCESS,
    payload: packagesList
  }
)

export const getPackageDetail = (payload) => (
  {
    type: GET_PACKAGE_DETAIL,
    payload
  }
)

export const getPackageDetailSuccess = (packageLogs) => (
  {
    type: GET_PACKAGE_DETAIL_SUCCESS,
    payload: packageLogs
  }
)

export const getPackagesLog = (payload) => (
    {
        type: GET_PACKAGES_LOG,
        payload
    }
)

export const getPackagesLogSuccess = (packagesList) => (
    {
        type: GET_PACKAGES_LOG_SUCCESS,
        payload: packagesList
    }
)

export const getLiveTrackingPackageDetail = (payload) => (
  {
    type: GET_LIVE_TRACKING_PACKAGE_DETAIL,
    payload
  }
)

export const getLiveTrackingPackageDetailSuccess = (packageLogs) => (
  {
    type: GET_LIVE_TRACKING_PACKAGE_DETAIL_SUCCESS,
    payload: packageLogs
  }
)
