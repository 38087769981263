import {
  GET_USERS_LIST, GET_USERS_LIST_SUCCESS,
  UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL
} from '../../constants/ActionTypes'

export const getUsersList = (payload) => (
  {
    type: GET_USERS_LIST,
    payload
  }
)

export const getUsersListSuccess = (usersList) => (
  {
    type: GET_USERS_LIST_SUCCESS,
    payload: usersList
  }
)

export const updateUser = (payload) => (
  {
    type: UPDATE_USER,
    payload
  }
)

export const updateUserSuccess = (payload) => (
  {
    type: UPDATE_USER_SUCCESS,
    payload
  }
)

export const updateUserFail = (payload) => (
  {
    type: UPDATE_USER_FAIL,
    payload
  }
)
